import { Box, Card, Divider, Flex, Tag, Text, Tooltip, useToast } from '@chakra-ui/react'
import React from 'react'
import { FaEye, FaUser, FaUserGraduate } from 'react-icons/fa6'


const TeamCard = ({ index, _id, role, companyName, description, name, administrator, member, candidate, setIsVisible, setTeam, hideList }) => {
    const toast = useToast()
    const getTagByRole = (role) => {
        if (role === "member") return "Membre"
        else if (role === "administrator") return "Administrateur"
        else if (role === "candidate") return "Candidat"
    }

    return (
        <Card key={index} className='secondary-gradient' boxShadow={"sm"} p={8} borderRadius={"xl"}>
            <Flex direction={"column"}>
                <Flex justifyContent={"space-between"} pb={4}>
                    <Text fontSize={"sm"} fontStyle={"italic"} fontWeight={"semibold"} color={"gray.700"}>{companyName}</Text>
                    <Tag bg={role === "administrator" ? 'green.400' : 'blue.600'} color="white">{getTagByRole(role)}</Tag>
                </Flex>
                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"} >{name}</Text>
                <Text fontSize={"md"} color={"gray.600"}>{description}</Text>
                {!hideList &&
                    <>
                        <Divider my={4} />
                        <Flex gap={8}>
                            <Tooltip label='Membres' placement='top'>
                                <Flex alignItems={"center"} gap={3}>
                                    <Box p={3} borderRadius={50} bg={"white"}>
                                        <FaUser color={"#285E61"} />
                                    </Box>
                                    <Text fontWeight={"semibold"} color={"gray.600"} fontSize={"3xl"}>{administrator?.length + member?.length}</Text>
                                </Flex>
                            </Tooltip>
                            <Tooltip label='Candidats' placement='top'>
                                <Flex alignItems={"center"} gap={3}>
                                    <Box p={3} borderRadius={50} bg={"white"}>
                                        <FaUserGraduate color={"#285E61"} />
                                    </Box>
                                    <Text fontWeight={"semibold"} color={"gray.600"} fontSize={"3xl"}>{candidate?.length}</Text>
                                </Flex>
                            </Tooltip>
                            <Tooltip label="Détails de l'équipe" placement='top'>
                                <Flex alignItems={"center"} gap={3} ml="auto">
                                    <Box p={3} borderRadius={50} bg={"white"} cursor={"pointer"} _hover={{ bg: "gray.200" }} transition={"0.2s"} onClick={() => {
                                        setIsVisible(true)
                                        setTeam({ id: _id, role: role })
                                    }}>
                                        <FaEye color={"#285E61"} />
                                    </Box>
                                </Flex>
                            </Tooltip>
                        </Flex>
                    </>
                }
            </Flex>
        </Card>
    )
}

export default TeamCard