import csrfFetch from '../Utils/CSRF';

export const register = async (user, invitationId) => {
    return await csrfFetch(`/users/register_candidate/${invitationId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
}


export const login = async (email, password) => {
    const response = await csrfFetch('/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    });

    return await response.json();
}

export const getUserInfos = async () => {
    const response = await csrfFetch('/users/infos')
    return await response.json();
}

export const logout = async () => {
    await csrfFetch('/users/logout')
}

export const updateUserPassword = async (oldPassword, newPassword, confirmNewPassword) => {
    const response = await csrfFetch('/users/password', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            oldPassword,
            newPassword,
            confirmNewPassword
        })
    });

    return response;
}


export const updateUserProfile = async (lastname, firstname, email, phone, oldPassword, newPassword, newPasswordConfirmation) => {
    const response = await csrfFetch('/users/', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lastname, firstname, email, phone, oldPassword, newPassword, newPasswordConfirmation })
    });

    return response;
}

export const updateProfilPicture = async (formData) => {
    const response = await csrfFetch('/users/profile_picture', {
        method: "POST",
        body: formData,
    });

    return response
}

export const sendResetPasswordLink = async (email) => {
    await csrfFetch('/users/reset-password', {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: { 'Content-Type': 'application/json' },
    })
}

export const resetPassword = async (passwordInvitation, password, confirmPassword) => {
    return await csrfFetch('/users/reset-password/' + passwordInvitation, {
        method: 'PUT',
        body: JSON.stringify({ password, confirmPassword }),
        headers: { 'Content-Type': 'application/json' },
    })
}