import { Avatar, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import StatusBadge from '../../Utils/StatusBadge'
import HomepageEmptyWork from '../HomepageEmptyWork'
import { fadeIn } from './Animation'

const ListCandidateWithoutQuiz = ({ statistiques }) => {
    const navigate = useNavigate()

    return (
        <Flex
            flex={1}
            gridArea={"2 / 1 / 4 / 3"}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            animation={`${fadeIn} .4s ease-in`}
        >
            {statistiques && statistiques.inactiveCandidate && statistiques.inactiveCandidate.length === 0 ? (
                <Flex justifyContent={"center"} direction={"column"} padding={20}>
                    <HomepageEmptyWork />
                </Flex>
            ) : null}

            {statistiques && statistiques.inactiveCandidate && statistiques.inactiveCandidate.length > 0 ?
                (<>
                    <TableContainer flex={1} mt={4} overflowX={{ base: "scroll", md: "auto" }}>
                        <Table variant='unstyled' size={"sm"}>
                            <Thead >
                                <Tr >
                                    <Th color={"gray.600"} pb={4} width={"10px"} />
                                    <Th color={"gray.600"} pb={4}>Nom</Th>
                                    <Th color={"gray.600"} pb={4}>Prénom</Th>
                                    <Th color={"gray.600"} pb={4}>Email</Th>
                                    <Th color={"gray.600"} pb={4}>Statut</Th>
                                </Tr>
                            </Thead>
                            <Tbody borderTop="1px solid #E2E8F0">
                                {statistiques && statistiques.inactiveCandidate && statistiques.inactiveCandidate.reverse().slice(0, 10).map((candidate, index) => {
                                    return <Tr key={index}
                                        // borderBottom={index === statistiques.inactiveCandidate.length - 1 ? "none" : "1px solid #E2E8F0"}
                                        cursor={"pointer"} _hover={{ bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)" }} onClick={() => { navigate('/candidat/' + candidate._id) }}
                                        color={"gray.700"} fontWeight={"semibold"}>
                                        <Td width={"10px"}>
                                            <Avatar size='sm' name={candidate.firstname + ' ' + candidate.lastname} src={candidate.blob} />
                                        </Td>
                                        <Td>{candidate.lastname}</Td>
                                        <Td>{candidate.firstname}</Td>
                                        <Td>{candidate.email}</Td>
                                        <Td><StatusBadge status={candidate.status} /></Td>
                                    </Tr>
                                })}

                            </Tbody>
                        </Table>
                    </TableContainer>
                </>) : null}
        </Flex>
    )
}

export default ListCandidateWithoutQuiz
