import { Box, Flex, Input, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { getTeamsByUser } from "../../API/Candidats";
import { UserContext } from '../../Authentication/ProtectedRoute';
import CandidatsTable from '../../Components/CandidatsTable';
import { fadeIn } from '../../Components/Dashboard/Animation';

const CandidatsBigTable = () => {
    const [candidats, setCandidats] = useState([])
    const [isCandidatCreationVisible, setIsCandidatCreationVisible] = useState(false)
    const { user } = useContext(UserContext);
    const [searchBar, setSearchBar] = useState("")
    const toast = useToast()

    useEffect(() => {
        loadUserCandidats()
    }, [])

    useEffect(() => {
    }, [user])

    const loadUserCandidats = async () => {
        try {
            const data = await getTeamsByUser();
            const transformedTeams = data.map(team => {
                return team.candidate.map(candidate => {
                    return {
                        team,
                        ...candidate
                    };
                });
            }).flat();
            setCandidats(transformedTeams)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des candidats',
                status: 'error',
            })
        }
    }


    return (
        <>
            <Flex bg="white" direction="column" gap={2} borderRadius={"xl"} mb={8} animation={`${fadeIn} .4s ease-in`}>
                <Box my={4}>
                    <Input variant='flushed' placeholder='Rechercher un candidat' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                </Box>
                <Box w='100%' borderRadius={"md"}>
                    <CandidatsTable candidats={candidats.filter(candidate =>
                        candidate.firstname.toLowerCase().includes(searchBar.toLowerCase()) ||
                        candidate.lastname.toLowerCase().includes(searchBar.toLowerCase()) ||
                        candidate.email.toLowerCase().includes(searchBar.toLowerCase()))}
                        reload={loadUserCandidats} />
                </Box>
            </Flex>
        </>
    )
}

export default CandidatsBigTable