import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Flex, Heading, Text, Button, useToast, Image, Box } from '@chakra-ui/react';
import { applyInvitation, denyInvitation, getInvitationByIdLogged } from '../API/Invitations';
import { FaArrowRight } from 'react-icons/fa6';

import InvitationIllustration from "../Resources/Images/invitations.svg";

const JoinTeamComponent = () => {
    const { invitationId } = useParams();
    const [invitation, setInvitation] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const fetchInvitation = async () => {
            setLoading(true);
            try {
                const response = await getInvitationByIdLogged(invitationId);
                if (response) {
                    setInvitation(response);
                }
            } catch (error) {
                toast({
                    title: 'Une erreur est survenue',
                    description: error.message,
                    status: 'error',
                })
                navigate("/");
            } finally {
                setLoading(false);
            }
        };
        fetchInvitation();
    }, [invitationId]);

    const acceptInvitation = async () => {
        setLoading(true);
        try {
            const response = await applyInvitation(invitationId);

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message);
            } else {
                toast({
                    title: 'Invitation acceptée',
                    description: 'L\'invitation a bien été acceptée',
                });
            }
        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            })
        } finally {
            setLoading(false);
            navigate('/');
        }
    };

    const refuseInvitation = async () => {
        setLoading(true);
        try {
            const response = await denyInvitation(invitationId);

            if (!response.ok) {
                toast({
                    title: 'Une erreur est survenue',
                    description: error.message,
                    status: 'error',
                })
            } else {
                toast({
                    title: 'Invitation refusee',
                    description: 'L\'invitation a bien été refusée',
                    status: 'success',
                });
            }

        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            })
        } finally {
            setLoading(false);
            navigate('/');
        }
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!invitation) {
        return <div>L'invitation n'existe pas</div>;
    }

    return (
        <Flex w={"100%"} h={"70vh"} justifyContent={"center"} alignItems={"center"}>
            <Box>
                <Image mx="auto" src={InvitationIllustration} alt={"invitations"} width="300px" height="300px" />
                <Heading as="h2" size="xl" mt={4}>
                    Bienvenue dans votre nouvelle équipe !
                </Heading>
                <Text mt={4}>
                    Vous avez reçu une invitation pour rejoindre l'équipe <span style={{ color: "#03B97C", fontWeight: "bold" }}>{invitation.team.name}</span> en tant que  {invitation.role === 'candidate' ? "candidat" : "recruteur"} !
                </Text>
                <Flex justifyContent="space-between" mt={8} gap={2} flexFlow={"row wrap"}>
                    <Button
                        flex={1}
                        colorScheme="red"
                        onClick={refuseInvitation}
                        isLoading={loading}
                        variant={"outline"}
                    >
                        Refuser
                    </Button>
                    <Button
                        flex={1}
                        colorScheme="brand"
                        onClick={acceptInvitation}
                        isLoading={loading}
                        rightIcon={<FaArrowRight />}
                    >
                        Accepter l'invitation
                    </Button>
                </Flex>
            </Box>
        </Flex>
    );
};

export default JoinTeamComponent;