import { Image, Text } from '@chakra-ui/react'
import React from 'react'
import WaitinResult from "../Resources/Images/waiting_results.svg";


const HomepageEmptyTable = ({ text, illustration }) => {
    return (
        <>
            <Image src={illustration ? illustration : WaitinResult} alt="All work" maxHeight={250} />
            <Text textAlign={"center"} fontSize={20} mt={10} color={"gray.600"}>{text}</Text>
        </>
    )
}

export default HomepageEmptyTable