import { Box, Button, Container, Flex, FormControl, FormLabel, Image, Input, useToast, Text } from '@chakra-ui/react'
import Footer from '../Layout/Footer'
import { useParams, useNavigate } from 'react-router-dom'
import AptitudeLogo from '../Resources/Logos/aptitude_recrutement.svg';
import { askForInvitation } from '../API/Invitations';
import { useEffect, useState } from 'react';
import { getOpenInvitationDetail } from "../API/Invitations";

const InvitationOffer = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { invitationId } = useParams();
  const [invitation, setInvitation] = useState(null);

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const data = await getOpenInvitationDetail(invitationId);
        if (data) {
          setInvitation(data);
        }
      } catch (error) {
        toast({
          title: 'Une erreur est survenue',
          description: error.message,
          status: 'error',
        })
      }
    };
    fetchInvitation();
  }, [invitationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('email');
    // Here you would typically send the invitation email request to your backend

    const response = await askForInvitation(invitationId, email);
    if (response.ok) {
      navigate("/");
      toast({
        title: "Invitation envoyée",
        description: "Vous allez recevoir une invitation à l'offre d'emploi par email.",
        status: 'success',
      });
    } else {
      toast({
        title: 'Une erreur est survenue',
        description: "Une erreur est survenue lors de l'envoi de l'invitation.",
        status: 'error',
      });
    }
  }

  return (
    <>
      <Container maxW="container.xl" p={4}>
        <Flex justifyContent="center" alignItems="center" minHeight={"60vh"} width={"100%"}>
          <Box width={"100%"} maxWidth={"800px"}>
            <Image mx={"auto"} mb={50} w="350px" src={AptitudeLogo} alt="logo aptitude" />
            <Text fontSize="lg" mb={4} textAlign="center">
              Entrez votre adresse e-mail pour recevoir une invitation à l'offre d'emploi "{invitation?.jobOfferId?.name}".
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired>
                <FormLabel>Adresse e-mail</FormLabel>
                <Input type="email" name="email" placeholder='Votre adresse e-mail' />
              </FormControl>
              <Flex gap={2} mt={4} ml="auto" width={"fit-content"} flexFlow={"row wrap"}>
                <Button colorScheme="brand" variant={"ghost"} onClick={() => navigate("/")}>Revenir à la page de connexion</Button>
                <Button colorScheme="brand" type="submit">Recevoir l'invitation</Button>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Container>
      <Footer />
    </>
  );
}

export default InvitationOffer;
