import { Box, Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Heading, Input, InputGroup, InputRightElement, Radio, RadioGroup, Select, Stack, Tag, TagCloseButton, TagLabel, Text, Textarea, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { FaArrowLeft, FaMinus, FaSave } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createJobOffer, getJobOfferById } from "../API/JobOffers"
import { UserContext } from '../Authentication/ProtectedRoute'
import CityAutocomplete from '../Components/CityAutocomplete'
import CreateJobOfferWysiwygComponent from '../Components/CreateJobOfferWysiwygComponent'
import { formatDateForInput } from "../Utils/FormatDate"
import { handleFormErrors } from "../Utils/HandleFormErrors"
import onKeyPressEnter from '../Utils/OnKeyPressEnter'
import { FaDeleteLeft } from 'react-icons/fa6'
const TEMPLATE_JOB_OFFER = "<h2 class=\"text-heading-large\">&Agrave; propos de l&rsquo;offre d&rsquo;emploi</h2> \
<p><strong>Profil du candidat <br></strong></p> \
<p>&nbsp;</p> \
<p><strong><strong>Activit&eacute;s </strong></strong></p> \
<p>&nbsp;</p> \
<p><strong><strong><strong> Contexte et environnement <br></strong></strong></strong></p> \
<p>&nbsp;</p> \
<p>&nbsp;</p>"

const JobOfferUpdateComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id === "new" ? null : params.id;
    const [newTag, setNewTag] = useState("")
    const [errors, setErrors] = useState({});
    const [jobOfferContent, setJobOfferContent] = useState({
        content: TEMPLATE_JOB_OFFER,
        name: "",
        tags: [],
        team: null,
        location: "",
        description: "",
        salary: [null, null],
        publicationDate: moment(),
        expirationDate: null
    })
    const { user } = useContext(UserContext);

    useMemo(() => {
        if (id) {
            // Get current job offer from API
            getJobOfferById(id).then((data) => setJobOfferContent(data))
        }
    }, [])

    const handleEditorChange = (content, editor) => {
        handleJobOfferChange("content", content)
    }

    const handleJobOfferChange = (field, value) => {
        setJobOfferContent({ ...jobOfferContent, [field]: value })
    }

    const handleJobOfferCreation = async () => {
        try {
            const response = await createJobOffer(id, jobOfferContent);

            const data = await response.json();
            if (!response.ok) {
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }
                throw new Error('Failed to fetch data');
            }

            setErrors({});
            navigate("/offre-emploi/" + data._id);
            toast({
                title: 'Offre d\'emploi sauvegardée'
            })

        } catch (error) {
            toast({
                title: 'Erreur lors de la sauvegarde de l\'offre d\'emploi',
                status: 'error'
            })
        }
    }

    const handleTags = () => {
        if (newTag === "") return;

        let { tags } = jobOfferContent;
        tags.push(newTag)
        handleJobOfferChange("tags", tags);
        setNewTag("");
    }

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button as={Link} to={"/offre-emploi"} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux offres d'emplois</Button>
                </Flex>
                <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>{id ? "Modification  " : "Création"} d'une offre d'emploi</Heading>
                <Flex gap={4} direction={"column"}>
                    <Flex flex={1} gap={4} className='secondary-gradient' border={"1px solid #EDF2F7"} boxShadow={"sm"} borderRadius={"md"} p={8} direction={"column"}>
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Informations générales</Text>
                        </Flex>
                        <Grid
                            templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
                            gap={4}
                            mb={8}
                        >
                            <FormControl flex={1} isInvalid={errors && errors.name}>
                                <FormLabel>Nom</FormLabel>
                                <Input bg={"white"} type='text' placeholder='Développeur javascript fullstack' value={jobOfferContent.name} onChange={(e) => handleJobOfferChange("name", e.target.value)} />
                                {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                            </FormControl>
                            <FormControl flex={1} isInvalid={errors && errors.team}>
                                <FormLabel>Choix de l'équipe</FormLabel>
                                <Select bg={"white"} placeholder='Sélectionner une équipe' onChange={(e) => handleJobOfferChange("team", e.target.value)} value={jobOfferContent.team?._id}>
                                    {user.teams && user.teams.map((element, index) => (
                                        <option value={element._id} key={index}>{element.name}</option>
                                    ))}
                                </Select>
                                {errors && errors.team && <FormErrorMessage>{errors.team}</FormErrorMessage>}
                            </FormControl>
                            <FormControl flex={1} isInvalid={errors && errors.publicationDate}>
                                <FormLabel>Date de publication de l'offre</FormLabel>
                                <Input bg={"white"} type="datetime-local" required value={jobOfferContent.publicationDate ? formatDateForInput(jobOfferContent.publicationDate) : undefined} onChange={(e) => handleJobOfferChange("publicationDate", e.target.value)} />
                                {errors && errors.publicationDate && <FormErrorMessage>{errors.publicationDate}</FormErrorMessage>}
                            </FormControl>
                            <FormControl flex={1} isInvalid={errors && errors.expirationDate}>
                                <FormLabel>Date de fin de l'offre</FormLabel>
                                <Input bg={"white"} type="datetime-local" value={jobOfferContent.expirationDate ? formatDateForInput(jobOfferContent.expirationDate) : undefined} onChange={(e) => handleJobOfferChange("expirationDate", e.target.value)} />
                                {errors && errors.expirationDate && <FormErrorMessage>{errors.expirationDate}</FormErrorMessage>}
                            </FormControl>
                        </Grid>
                    </Flex>

                    <Flex flex={1} gap={4} className='secondary-gradient' border={"1px solid #EDF2F7"} boxShadow={"sm"} borderRadius={"md"} p={8} direction={"column"}>
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Détails de l'offre</Text>
                        </Flex>
                        <Grid
                            templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
                            gap={4}
                            mb={8}
                        >
                            <FormControl flex={1} >
                                <FormLabel>Lieu</FormLabel>
                                <Box display={"flex"} gap={4}>
                                    <CityAutocomplete value={jobOfferContent.location} onChange={(value) => handleJobOfferChange('location', value)} />
                                    <Select bg={"white"} placeholder='Sélectionner le type de télétravail' onChange={(e) => handleJobOfferChange("remote", e.target.value)} value={jobOfferContent?.remote ?? undefined}>
                                        <option value={"Télétravail"} >Télétravail</option>
                                        <option value={"Hybride"} >Hybride</option>
                                        <option value={"Présentiel"} >Présentiel</option>
                                    </Select>
                                </Box>

                            </FormControl>
                            <FormControl flex={1}>
                                <FormLabel>Salaire</FormLabel>
                                <Flex gap={2} alignItems={"center"}>
                                    <Input flex={1} bg={"white"} type='number' placeholder='Salaire minimum' value={jobOfferContent?.salary?.[0] ?? "undefined"} onChange={(e) => { const salary = jobOfferContent.salary; salary[0] = parseInt(e.target.value); handleJobOfferChange("salary", salary) }} />
                                    <FaMinus color='#718096' />
                                    <Input flex={1} bg={"white"} type='number' placeholder='Salaire maximum' value={jobOfferContent?.salary?.[1] ?? "undefined"} onChange={(e) => { const salary = jobOfferContent.salary; salary[1] = parseInt(e.target.value); handleJobOfferChange("salary", salary) }} />
                                </Flex>
                            </FormControl>
                            <FormControl flex={1}>
                                <FormLabel>Visibilité</FormLabel>
                                <RadioGroup value={jobOfferContent.type || 'public'} onChange={(e) => handleJobOfferChange('type', e)} >
                                    <Stack spacing={4} direction='row'>
                                        <Radio colorScheme='green' value='public'>
                                            Publique
                                        </Radio>
                                        <Radio colorScheme='green' value='private'>
                                            Privée
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                            <FormControl flex={1} isInvalid={errors && errors.contractType}>
                                <FormLabel>Type de contrat</FormLabel>
                                <Select bg={"white"} placeholder='Sélectionner un type de contrat' onChange={(e) => handleJobOfferChange("contractType", e.target.value)} value={jobOfferContent?.contractType ?? undefined}>
                                    <option value={"CDI"} >CDI</option>
                                    <option value={"CDD"} >CDD</option>
                                    <option value={"Stage"} >Stage</option>
                                    <option value={"Alternance"} >Alternance</option>
                                </Select>
                                {errors && errors.contractType && <FormErrorMessage>{errors.contractType}</FormErrorMessage>}
                            </FormControl>
                            <FormControl flex={1} isInvalid={errors && errors.contractTime}>
                                <FormLabel>Type de poste</FormLabel>
                                <Select bg={"white"} placeholder='Sélectionner un type de poste' onChange={(e) => handleJobOfferChange("contractTime", e.target.value)} value={jobOfferContent?.contractTime ?? undefined}>  
                                    <option value={"Temps partiel"} >Temps partiel</option>
                                    <option value={"Temps complet"} >Temps complet</option>
                                </Select>
                                {errors && errors.contractTime && <FormErrorMessage>{errors.contractTime}</FormErrorMessage>}
                            </FormControl>
                            <FormControl flex={3}>
                                <FormLabel>Compétences associées</FormLabel>
                                <Flex alignItems="start" gap={4} w={"100%"} direction={"column"}>
                                    <InputGroup width={"md"}>
                                        <Input
                                            bg={"white"}
                                            type={'text'}
                                            placeholder='Javascript, Excel, SCRUM, etc...'
                                            value={newTag}
                                            onChange={(e) => setNewTag(e.target.value)}
                                            onKeyDown={(e) => onKeyPressEnter(e, handleTags)}
                                        />
                                        <InputRightElement width='4.5rem' pr={1}>
                                            <Button size='xs' onClick={handleTags}>
                                                Ajouter
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex align="center" flexWrap={"wrap"} gap={1}>
                                        {jobOfferContent.tags && jobOfferContent.tags.map((tag, index) => <Tag
                                            key={index}
                                            variant='solid'
                                            colorScheme='brand'
                                        >
                                            <TagLabel>{tag}</TagLabel>
                                            <TagCloseButton onClick={() => handleJobOfferChange("tags", [...jobOfferContent.tags.slice(0, index), ...jobOfferContent.tags.slice(index + 1)])} />
                                        </Tag>)}
                                    </Flex>
                                </Flex>
                            </FormControl>
                        </Grid>
                    </Flex>

                    <Flex flex={1} gap={4} className='secondary-gradient' border={"1px solid #EDF2F7"} boxShadow={"sm"} borderRadius={"md"} p={8} direction={"column"}>
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Contenu de l'offre</Text>
                        </Flex>
                        <Flex direction="column" gap={4}>
                            <FormControl isInvalid={errors && errors.description}>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    bg={"white"}
                                    value={jobOfferContent.description}
                                    onChange={(e) => handleJobOfferChange("description", e.target.value)}
                                    placeholder="Description"
                                    size="sm"
                                />
                                {errors && errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={errors && errors.content}>
                                <FormLabel>Contenu</FormLabel>
                                <CreateJobOfferWysiwygComponent handleEditorChange={handleEditorChange} defaultValue={jobOfferContent.content} />
                                {errors && errors.content && <FormErrorMessage>{errors.content}</FormErrorMessage>}
                            </FormControl>
                        </Flex>
                        <Flex mt={4} justifyContent="flex-end" gap={4}>
                            {!id ? <Button variant={"ghost"} colorScheme="brand" leftIcon={<FaDeleteLeft />} onClick={() => navigate('/offre-emploi')}>Annuler la création</Button> : null}
                            <Button onClick={handleJobOfferCreation} leftIcon={<FaSave />} colorScheme="brand">Sauvegarder l'offre d'emploi</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
        </>
    )
}

export default JobOfferUpdateComponent
