import React, { useEffect, useRef, useState } from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Divider,
    Text,
    useToast
} from '@chakra-ui/react';
import { FaPlus, FaTrash } from "react-icons/fa6";
import { addOrDeleteQuiz } from '../../../API/JobOffers';
import { loadUserQuizzes } from '../../../API/Quizzes';

const QuizAssignation = ({ isOpen, onClose, jobOffer, setJobToAssign }) => {
    const cancelRef = useRef();
    const toast = useToast();
    const [quizzes, setQuizzes] = useState([])
    const [quizzesAlreadyAdded, setQuizAlreadyAdded] = useState(jobOffer?.quizzes || [])

    useEffect(() => {
        try {
            loadUserQuizzes(setQuizzes);
        } catch (error) {
            toast({
                title: "Erreur lors du chargement des questionnaires",
                status: "error"
            })
        }
    }, [])

    const handleSubmitAddOrDeleteQuiz = async (quizId) => {
        try {
            const response = await addOrDeleteQuiz(jobOffer._id, quizId);

            if (!response.ok) {
                throw new Error('Failed to assign questionnaire');
            }

            const isQuizAlreadyAdded = quizzesAlreadyAdded.includes(quizId);
            setQuizAlreadyAdded(
                isQuizAlreadyAdded
                    ? quizzesAlreadyAdded.filter(id => id !== quizId)
                    : [...quizzesAlreadyAdded, quizId]
            )

            toast({
                title: isQuizAlreadyAdded ? "Questionnaire supprimé" : 'Questionnaire assigné',
            })
        } catch (error) {
            toast({
                title: "Erreur lors de l'assignation du questionnaire",
                status: "error"
            })
        }
    }
    return (
        <AlertDialog
            size={"xl"}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                onClose()
                setJobToAssign(null)
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Ajouter un questionnaire à l'offre {jobOffer?.name}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {quizzes.length === 0 && <Text fontSize='sm'>Aucun questionnaire disponible</Text>}
                        {jobOffer && quizzes && quizzes.map((quiz, index) => {
                            const isQuizAlreadyAdded = quizzesAlreadyAdded.includes(quiz._id);

                            return <React.Fragment key={index}>
                                <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                    <Text fontSize='sm'>{quiz.name}</Text>
                                    <Button rightIcon={isQuizAlreadyAdded ? <FaTrash /> : <FaPlus />} size={"sm"} variant={"ghost"} color={isQuizAlreadyAdded ? "red" : "brand.500"} onClick={async () => {
                                        await handleSubmitAddOrDeleteQuiz(quiz._id);
                                    }}>{!isQuizAlreadyAdded ? "Lier à cette offre" : "Supprimer de cette offre"}</Button>
                                </Box>
                                <Divider />
                            </React.Fragment>
                        })}
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setJobToAssign(null)
                                onClose()
                            }}>
                                Fermer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default QuizAssignation;

