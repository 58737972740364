import csrfFetch from '../Utils/CSRF';

export const getPendingInvitations = async () => {
    const request = await csrfFetch('/invitations/pending')

    return await request.json();
}

export const getMembersInvitations = async () => {
    const request = await csrfFetch('/invitations/members/pending')

    return await request.json();
}

export const sendInvitation = async (emails, team, role = "candidate") => {
    const request = await csrfFetch('/invitations', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            emails,
            team,
            role
        })
    })

    return await request.json();
}

export const getInvitationById = async (invitationId) => {
    const response = await csrfFetch(`/invitations/${invitationId}`);

    return response;
}

export const deleteInvitation = async (invitationId) => {
    const request = await csrfFetch('/invitations/' + invitationId, {
        method: 'DELETE'
    })

    return request;
}

export const getInvitationByIdLogged = async (invitationId) => {
    const request = await csrfFetch('/invitations/' + invitationId)

    return await request.json();
}

export const denyInvitation = async (invitationId) => {
    const response = await csrfFetch(`/invitations/${invitationId}/refuse`, {
        method: 'PUT'
    });

    return response;
}

export const applyInvitation = async (invitationId) => {
    const response = await csrfFetch(`/invitations/${invitationId}/approve`, {
        method: 'PUT'
    });

    return response;
}

export const getLoggedInvitations = async (setData) => {
    const response = await csrfFetch('/invitations/user/get');

    if (!response.ok) {
        throw new Error('Failed to fetch invitations');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data);
    }
}

export const getOpenInvitationLinks = async (jobOfferId, setData) => {
    const response = await csrfFetch(`/open-invitations/${jobOfferId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch open invitations');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data);
    }
}

export const addOpenInvitationLink = async (jobOfferId, endDate, nbMax) => {
    const response = await csrfFetch(`/open-invitations`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            jobOfferId,
            endDate,
            nbMax
        })
    });

    return response;
}

export const askForInvitation = async (invitationId, email) => {
    const response = await csrfFetch(`/open-invitations/${invitationId}/ask`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    return response;
}

export const getOpenInvitationDetail = async (invitationId) => {
    const response = await csrfFetch(`/open-invitations/get/${invitationId}`);

    return await response.json();
}