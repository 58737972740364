import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Container, Flex, Heading, IconButton, Image, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaEye, FaPenToSquare, FaTrash } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addOrRemoveJobOffer } from '../API/Candidats';
import { getJobOfferApplications } from "../API/JobApplication";
import { addOrDeleteQuiz, getJobOfferByIdWithCandidates, linkJobOfferToCandidate, removeJobOffer } from "../API/JobOffers";
import { getOpenInvitationLinks } from "../API/Invitations"
import { UserContext } from '../Authentication/ProtectedRoute';
import AddCandidatToJobOfferDialog from '../Components/JobOffers/DialogJobOffers/AddCandidatToJobOfferDialog';
import AddInvitationLink from '../Components/JobOffers/DialogJobOffers/AddInvitationLink';
import TableDeletionDialog from '../Components/JobOffers/DialogJobOffers/TableDeletionDialog';
import JobOfferHeader from '../Components/JobOffers/JobOfferHeader';
import EmptyCandidates from "../Resources/Images/empty_candidate.svg";
import StatusBadge from '../Utils/StatusBadge';
import { jobApplicationStatus } from "../Utils/translate";
import CopyToClipboard from '../Utils/CopyToClipboard';

const JobOfferComponent = () => {
    const { id } = useParams();
    const [jobOffer, setJobOffer] = useState({})
    const [candidates, setCandidates] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [candidateToDelete, setCandidateToDelete] = useState(null)
    const [jobApplications, setJobApplications] = useState([])
    const [invitationLinks, setInvitationLinks] = useState([])
    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()
    const toast = useToast()
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { isOpen: isOpenDeletion, onOpen: onOpenDeletion, onClose: onCloseDeletion } = useDisclosure()
    const { isOpen: isOpenAddCandidat, onOpen: onOpenAddCandidat, onClose: onCloseAddCandidat } = useDisclosure()
    const { isOpen: isOpenAddLink, onOpen: onOpenAddLink, onClose: onCloseAddLink } = useDisclosure()
    const cancelRefDeletion = useRef()
    const cancelRefAddCandidat = useRef()
    const cancelRefAddLink = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const [allowedCandidateList, setAllowedCandidateList] = useState(null)
    const { formatDateForPrint } = require('../Utils/FormatDate');

    useEffect(() => {
        try {
            getJobOffer()
            getJobOfferApplications(id, setJobApplications)
            getOpenInvitationLinks(id, setInvitationLinks)
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Une erreur s'est produite. Merci de réssayer plus tard.",
                status: "error"
            })
        }
    }, [])

    useEffect(() => {
        let data = user.teams.map(team => team.candidate)
        let filteredData = data.flat().filter(candidate => !candidates.some(c => c._id === candidate._id));
        setAllowedCandidateList(filteredData);
    }, [user, jobOffer])

    const getJobOffer = async () => {
        const data = await getJobOfferByIdWithCandidates(id)
        setJobOffer(data.jobOffer)
        setCandidates(data.candidates)
    }


    const handleAddCandidate = async (candidateId) => {
        const response = await linkJobOfferToCandidate(jobOffer._id, candidateId)

        if (response === 200) {
            toast({
                title: 'Candidat ajouté',
            })
        }
        getJobOffer();
    }

    const handleDeleteCandidate = async (candidateId) => {
        setCandidateToDelete(candidateId)
        setIsOpen(true)
    }

    const confirmJobOfferDeletation = async () => {
        const response = await addOrRemoveJobOffer(candidateToDelete._id, id)

        if (response.ok) {
            const newCandidates = candidates.filter(candidate => candidate._id !== candidateToDelete._id)
            setCandidates(newCandidates)
            toast({
                title: 'Candidat retiré',
            })
            setCandidateToDelete(null)
            setIsOpen(false)
            getJobOffer();
        } else {
            toast({
                title: 'Erreur lors de la suppression du candidat',
                status: 'error'
            })
        }
    }

    const handleDeletion = async () => {
        try {
            if (!await removeJobOffer(toDeletion)) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    title: 'Offre d\'emploi supprimée'
                })
                navigate('/offre-emploi')
            }
        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression de l\'offre d\'emploi',
                status: 'error',
            })
        }
    }

    const handleSubmitAddOrDeleteQuiz = async (quizId) => {
        try {
            const response = await addOrDeleteQuiz(id, quizId);

            if (!response.ok) {
                throw new Error('Failed to assign questionnaire');
            }

            toast({
                title: "Questionnaire supprimé"
            })
            getJobOffer()
        } catch (error) {
            toast({
                title: 'Erreur lors de l\'assignation du questionnaire',
                status: 'error'
            })
        }
    }

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button as={Link} to="/offre-emploi" size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux offres d'emploi</Button>
                </Flex>

                <Flex gap={4} >
                    <Flex direction={"column"} width="-webkit-fill-available" >
                        <Flex direction={["column", "column", "column", "row"]} justifyContent={"space-between"} gap={4} mb={12}>
                            <Flex direction={"column"} w="100%">
                                <Flex alignItems="baseline" className='brand-gradient' gap={4} p={8} justifyContent={"space-between"} rounded={"md"}>
                                    <Flex direction={"column"}>
                                        <Flex alignItems={"flex-start"} direction={"column"} >
                                            <Tag fontSize='xs' type='whiteAlpha'>{jobOffer._id}</Tag>
                                            <Text as='b' color={"white"} fontSize='4xl'>{jobOffer.name}</Text>
                                        </Flex>
                                        <Text maxWidth={"90%"} color={"white"}>
                                            {jobOffer.description}
                                        </Text>
                                    </Flex>
                                    {user.role !== "candidate" ? <Flex ml="auto">
                                        <IconButton
                                            rounded="full"
                                            as={Link}
                                            to={`/offre-emploi/update/${jobOffer._id}`}
                                            size='sm'
                                            aria-label='Update'
                                            colorScheme='brand'
                                            icon={<FaPenToSquare />}
                                            mr={1}
                                        />
                                        <IconButton
                                            rounded="full"
                                            size='sm'
                                            colorScheme='red'
                                            aria-label='Delete'
                                            icon={<FaTrash />}
                                            onClick={() => {
                                                setToDeletion(jobOffer._id)
                                                onOpenDeletion()
                                            }}
                                        />
                                    </Flex> : null}
                                </Flex>
                                <Flex py={12} bg="white" rounded={"md"}>
                                    <div id='joboffer' dangerouslySetInnerHTML={{ __html: jobOffer?.content }} />
                                </Flex>
                            </Flex>

                            <Flex direction={"column"}>
                                {jobOffer &&
                                    <JobOfferHeader {...jobOffer} />
                                }
                            </Flex>
                        </Flex>
                        <Flex direction="column" gap={4} mb={12} p={8} bg={"white"} rounded={"md"}>
                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Liens d'invitation</Heading>
                                <Button size={"sm"} onClick={onOpenAddLink}>Créer un lien</Button>
                            </Flex>
                            <TableContainer width={"100%"}>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr userSelect={"none"}>
                                            <Th>
                                                Lien
                                            </Th>
                                            <Th>
                                                Utilisation actuelle
                                            </Th>
                                            <Th>
                                                Utilisation maximum
                                            </Th>
                                            <Th>
                                                Date de fin
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {invitationLinks.map((link, index) => (
                                            <Tr _hover={{ bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)", cursor: "pointer" }} key={index}>
                                                <Td onClick={() => {
                                                    CopyToClipboard(`${window.location.origin}/invitation-offre/${link._id}`)
                                                    toast({
                                                        title: "Lien copié",
                                                    })
                                                }}>
                                                    <Tooltip label="Cliquez pour copier l'invitation" placement='top'>
                                                        {link._id}
                                                    </Tooltip>
                                                </Td>
                                                <Td>{link.nbUsed}</Td>
                                                <Td>{link.nbMax}</Td>
                                                <Td>{formatDateForPrint(link.endDate)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Flex>
                        {jobApplications && jobApplications.length > 0 &&
                            <Flex direction="column" gap={4} mb={12} p={8} bg={"white"} rounded={"md"}>
                                <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Candidatures</Heading>
                                <TableContainer width={"100%"}>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr userSelect={"none"}>
                                                <Th>
                                                    Nom
                                                </Th>
                                                <Th>
                                                    Prénom
                                                </Th>
                                                <Th>
                                                    Email
                                                </Th>
                                                <Th>
                                                    Status
                                                </Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {jobApplications.map((jobApplication, index) => (
                                                <Tr _hover={{ bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)", cursor: "pointer" }} key={index}>
                                                    <Td onClick={() => navigate(`/offre-emploi/${id}/candidature/${jobApplication._id}`)} >{jobApplication.candidate.lastname}</Td>
                                                    <Td onClick={() => navigate(`/offre-emploi/${id}/candidature/${jobApplication._id}`)}>{jobApplication.candidate.firstname}</Td>
                                                    <Td onClick={() => navigate(`/offre-emploi/${id}/candidature/${jobApplication._id}`)}>{jobApplication.candidate.email}</Td>
                                                    <Td onClick={() => navigate(`/offre-emploi/${id}/candidature/${jobApplication._id}`)}><StatusBadge status={jobApplicationStatus[jobApplication.status]} /></Td>
                                                    <Td>
                                                        <Tooltip label='Visualiser' placement='top'>
                                                            <IconButton
                                                                rounded="full"
                                                                as={Link}
                                                                to={`/offre-emploi/${id}/candidature/${jobApplication._id}`}
                                                                aria-label='Read'
                                                                isRound
                                                                icon={<FaEye />}
                                                                mr={1}
                                                            />
                                                        </Tooltip>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Flex>
                        }
                        {candidates && <Flex direction="column" gap={4} mb={12} p={8} bg={"white"} rounded={"md"}>
                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Candidats</Heading>
                                <Button size={"sm"} onClick={onOpenAddCandidat}>Ajouter un candidat</Button>
                            </Flex>
                            {candidates.length > 0 ? <TableContainer width={"100%"}>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr userSelect={"none"}>
                                            <Th>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <Text>Nom</Text>
                                                </Flex>
                                            </Th>
                                            <Th>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <Text>Prénom</Text>
                                                </Flex>
                                            </Th>
                                            <Th>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <Text>Email</Text>
                                                </Flex>
                                            </Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {candidates.map((candidate, index) => (
                                            <Tr _hover={{ cursor: "pointer", bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)" }} key={index}>
                                                <Td onClick={() => navigate(`/candidat/${candidate._id}`)} >{candidate.lastname}</Td>
                                                <Td onClick={() => navigate(`/candidat/${candidate._id}`)}>{candidate.firstname}</Td>
                                                <Td onClick={() => navigate(`/candidat/${candidate._id}`)}>{candidate.email}</Td>
                                                <Td>
                                                    <Tooltip label="Retirer le candidat de cette offre" placement='top'>
                                                        <IconButton
                                                            rounded="full"
                                                            onClick={() => handleDeleteCandidate(candidate)}
                                                            colorScheme='red'
                                                            aria-label='Delete'
                                                            isRound
                                                            icon={<FaTrash />}
                                                        />
                                                    </Tooltip>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer> :
                                <Flex p={4} direction={"column"} gap={4} alignItems={"center"}>
                                    <Image w={"400px"} src={EmptyCandidates} alt="No candidates" maxHeight={500} />
                                    <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Aucun candidat n'est associé à cette offre !</Text>
                                </Flex>}

                        </Flex>
                        }

                        {jobOffer.quizzes && jobOffer.quizzes.length > 0 &&
                            <Flex direction="column" gap={4} mb={12} p={8} bg={"white"} rounded={"md"}>
                                <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Questionnaires</Heading>
                                <TableContainer width={"100%"} >
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr userSelect={"none"}>
                                                <Th>
                                                    <Flex alignItems={"center"} gap={2}>
                                                        <Text>Nom</Text>
                                                    </Flex>
                                                </Th>
                                                <Th>Description</Th>
                                                <Th>
                                                    <Flex alignItems={"center"} gap={2}>
                                                        <Text>Équipe</Text>
                                                    </Flex>
                                                </Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {jobOffer.quizzes.map((quiz, index) => (
                                                <Tr key={index} _hover={{ cursor: "pointer", bg: "lightBg.700" }}>
                                                    <Td onClick={() => navigate(`/questionnaire/${quiz._id}`)}>{quiz.name}</Td>
                                                    <Td onClick={() => navigate(`/questionnaire/${quiz._id}`)}>{quiz.description}</Td>
                                                    <Td onClick={() => navigate(`/questionnaire/${quiz._id}`)}>{quiz.teamOwner.name}</Td>
                                                    <Td>
                                                        <Tooltip label='Supprimer la questionnaire de cette offre' placement='top'>
                                                            <IconButton
                                                                rounded="full"
                                                                onClick={() => {
                                                                    handleSubmitAddOrDeleteQuiz(quiz._id)
                                                                }}
                                                                colorScheme='red'
                                                                aria-label='Delete'
                                                                icon={<FaTrash />}
                                                            />
                                                        </Tooltip>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Flex>
                        }
                    </Flex>
                </Flex>

            </Container >
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Supprimer le candidat
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer <b>{candidateToDelete?.firstname} {candidateToDelete?.lastname}</b> de cette offre d'emploi?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme="red" onClick={confirmJobOfferDeletation} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <TableDeletionDialog isOpen={isOpenDeletion} onClose={onCloseDeletion} toDeletion={toDeletion} setToDeletion={setToDeletion} handleDeletion={handleDeletion} cancelRef={cancelRefDeletion} />
            <AddCandidatToJobOfferDialog addCandidate={handleAddCandidate} isOpen={isOpenAddCandidat} onClose={onCloseAddCandidat} cancelRef={cancelRefAddCandidat} jobOffer={jobOffer} allowedCandidateList={allowedCandidateList} />
            <AddInvitationLink isOpen={isOpenAddLink} onClose={onCloseAddLink} cancelRef={cancelRefAddLink} invitationLinks={invitationLinks} setInvitationLinks={setInvitationLinks} jobOfferId={id} />
        </>
    )

}

export default JobOfferComponent

