import { Box, Container, Flex, Grid, GridItem, Heading, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getPublicJobOffers } from '../../API/JobOffers';
import JobOfferCard from '../../Components/JobOffers/JobOfferCard';
import { fadeIn } from '../../Components/Dashboard/Animation';
import JobOffersEmpty from '../../Resources/Images/joboffers_empty.svg'
import HomepageEmptyTable from '../../Components/HomepageEmptyTable';

const JobSearchComponent = () => {
    const [jobs, setJobs] = useState(null)

    useEffect(() => {
        loadUserJobOffers()
    }, [])

    const loadUserJobOffers = async () => {
        const response = await getPublicJobOffers();
        setJobs(response)
    }

    return (
        <Container maxW="full" p={4}>
            <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`}>
                <Heading pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Les offres d'emplois</Heading>
                {jobs && jobs.length == 0 ? <HomepageEmptyTable text={"Les offres d'emploi apparaîtront ici"} illustration={JobOffersEmpty} /> : null}
                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    {jobs && jobs.map((job, index) => {
                        return <GridItem key={index}>
                            <JobOfferCard job={job} />
                        </GridItem>
                    })}
                </Grid>
            </Flex>
        </Container>
    )
}

export default JobSearchComponent