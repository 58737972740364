import csrfFetch from '../Utils/CSRF';

export const updateQuestion = async (data) => {
    return await csrfFetch('/questions/update-question-image', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(data)
    })
}