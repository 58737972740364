import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { createContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfos, logout } from "../API/Users";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";
import PhoneHeader from '../Layout/PhoneHeader';
import NotFoundComponent from '../Routes/NotFoundComponent';
import ScrollToTop from '../Utils/ScrollToTop';

// Création du UserContext
export const UserContext = createContext({
    user: { firstname: undefined, lastname: undefined, role: undefined, email: undefined, teams: [] },
    setUser: () => { },
    loadData: () => { }
});

const ProtectedRoute = ({ component: Component, roles, ...restOfProps }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({ firstname: undefined, lastname: undefined, roles: undefined, email: undefined, teams: [] });
    const [loading, setLoading] = useState(false); // Ajout d'un état de chargement
    const location = useLocation(); // Obtenir la route actuelle
    const [notFound, setNotFound] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [largerThanLg] = useMediaQuery('(min-width: 1600px)')

    const loadData = async () => {
        try {
            const loadedUser = await getUserInfos();
            if (loadedUser.message) {
                await logout();
                navigate("/login", { state: { from: location } });
                return;
            } else if (roles && !roles.includes(loadedUser.role)) {
                setNotFound(true);
            } else {
                setUser(loadedUser);
                setNotFound(false);
            }
        } catch (error) {
            navigate("/login", { state: { from: location } });
            return;
        } finally {
            setLoading(false);
        }
    };

    useMemo(() => {
        loadData();
    }, [navigate]);

    if (loading) {
        return <div></div>;
    }

    if (notFound) {
        return <NotFoundComponent />;
    }

    return (
        <UserContext.Provider value={{ user, setUser, loadData }}>
            <ScrollToTop />
            <Flex direction={"row"}>
                <Navbar largerThanLg={largerThanLg} isMobile={isMobile} />
                <Flex direction={"column"} bg={"white"} p={isMobile ? 0 : 5} m={0} w={"100%"}>
                    {isMobile ? <PhoneHeader /> : null}
                    <Box flex={1}>
                        <Component {...restOfProps} />
                    </Box>
                    <Footer />
                </Flex>
            </Flex>
        </UserContext.Provider>
    );
};

export default ProtectedRoute;