import { Box, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { fadeIn } from '../Components/Dashboard/Animation';
import { useNavigate } from 'react-router-dom';
import { getTeamsByUser } from "../API/Candidats";
import { getLoggedJobOffer } from '../API/JobOffers';
import { getStatistics } from '../API/Statistics';
import { UserContext } from "../Authentication/ProtectedRoute";
import DashboardStatistic from '../Components/Dashboard/DashboardStatistic';
import ListCandidate from '../Components/Dashboard/ListCandidate';
import ListCandidateWithoutQuiz from '../Components/Dashboard/ListCandidateWithoutQuiz';
import ListJobOffers from '../Components/Dashboard/ListJobOffers';
import ListRunningQuiz from '../Components/Dashboard/ListRunningQuiz';
import ListDoneQuiz from '../Components/Dashboard/ListDoneQuiz';

const Homepage = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [teams, setTeams] = useState([])
    const [statistiques, setStatistiques] = useState(null)
    const [jobOffers, setJobOffers] = useState([])
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (user && user.role === "candidate") navigate("/")
    }, [user, teams])

    const loadUserCandidats = async () => {
        try {
            const data = await getTeamsByUser();
            setTeams(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const loadUserJobOffers = async () => {
        try {
            await getLoggedJobOffer(setJobOffers)
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setJobOffers([])
        }
    }

    const loadStatistiques = async () => {
        try {
            await getStatistics(setStatistiques);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        loadUserJobOffers()
        loadUserCandidats()
        loadStatistiques()
        setLoading(false)
    }, [])

    if (!user) return null

    return (
        <>
            <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Tableau de bord</Heading>
            <Flex direction={["row", isMobile ? "row" : "row", "column"]} gap={4} p={4} >
                <Flex flex={3} gap={4} direction={isMobile ? "column" : "row"} wrap={isMobile ? "wrap" : "nowrap"}>
                    <DashboardStatistic isLoading={loading} number={teams.reduce((acc, team) => acc + team.candidate.length, 0)} text="Nombre de candidats" color={"brand.500"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.newCandidatesCounter} text="Nouveaux candidats" color={"brand.500"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.runningCandidatesCounter} text="Candidatures en cours" color={"brand.500"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.keptCandidatesCounter} text="Candidature retenues" color={"brand.500"} isMobile={isMobile} />
                </Flex>
                <Flex flex={3} gap={4} direction={isMobile ? "column" : "row"} wrap={isMobile ? "wrap" : "nowrap"}>
                    <DashboardStatistic isLoading={loading} number={jobOffers && jobOffers.length} text="Nombre d'offres d'emploi" color={"lightBg.50"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.inactiveCandidate && statistiques.inactiveCandidate.length} text="Candidats en attente de questionnaires" color={"lightBg.50"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.runningQuiz && statistiques.runningQuiz.length} text="Questionnaires en cours" color={"lightBg.50"} isMobile={isMobile} />
                    <DashboardStatistic isLoading={loading} number={statistiques && statistiques.doneQuiz && statistiques.doneQuiz.length} text="Questionnaires terminés" color={"lightBg.50"} isMobile={isMobile} />
                </Flex>
            </Flex>
            {!isMobile ?
                <Flex gap={4} p={4} pt={0} flexWrap={"wrap"} direction={"column"}>
                    <Box
                        flex={1}
                        gridArea={"2 / 3 / 4 / 6"}
                        direction={"column"}
                        p={4}
                        overflowX={{ base: "hidden", md: "unset" }}
                        width={["100%", "100%", "unset"]}
                        boxShadow={"sm"}
                        className="secondary-gradient"
                        borderRadius={"xl"}
                        animation={`${fadeIn} .4s ease-in`}
                        minHeight={"500px"}
                    >
                        <Text fontSize={"xl"} fontWeight={"semibold"} color={"blue.800"}>Statistiques candidats</Text>
                        <Tabs>
                            <TabList>
                                <Tab value="candidates" _selected={{ color: "brand.500", borderBottom: "2px solid" }} >Liste des candidats</Tab>
                                <Tab value="candidateswithoutquiz" _selected={{ color: "brand.500", borderBottom: "2px solid" }}>Candidats sans questionnaire</Tab>
                                <Tab value="runningquiz" _selected={{ color: "brand.500", borderBottom: "2px solid" }}>Questionnaires en cours</Tab>
                                <Tab value="endedquiz" _selected={{ color: "brand.500", borderBottom: "2px solid" }}>Questionnaires terminés</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel value="candidates"><ListCandidate teams={teams} /></TabPanel>
                                <TabPanel value="candidateswithoutquiz"><ListCandidateWithoutQuiz statistiques={statistiques} /></TabPanel>
                                <TabPanel value="runningquiz"><ListRunningQuiz statistiques={statistiques} /></TabPanel>
                                <TabPanel value="endedquiz"><ListDoneQuiz statistiques={statistiques} /></TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                    <Box flex={1}
                        gridArea={"2 / 3 / 4 / 6"}
                        direction={"column"}
                        p={4}
                        overflowX={{ base: "hidden", md: "unset" }}
                        width={["100%", "100%", "unset"]}
                        boxShadow={"sm"}
                        className="secondary-gradient"
                        borderRadius={"xl"}
                        animation={`${fadeIn} .4s ease-in`}
                        minHeight={"500px"}
                    >
                        <Text fontSize={"xl"} fontWeight={"semibold"} color={"blue.800"}>Statistiques d'offres d'emplois</Text>
                        <Tabs>
                            <TabList>
                                <Tab value="candidates" _selected={{ color: "brand.500", borderBottom: "2px solid" }}>Mes offres d'emploi</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel value="candidates"><ListJobOffers isLoading={loading} jobOffers={jobOffers} reload={loadUserJobOffers} /></TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex >
                : null
            }

        </>
    )
}

export default Homepage