import csrfFetch from '../Utils/CSRF';

export const getFileByKey = async (key) => {
    return await csrfFetch('/s3/get', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}

export const deleteFileByKey = async (key) => {
    return await csrfFetch('/s3/delete', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}

export const deleteAllFromParentPrefix = async (key) => {
    return await csrfFetch('/s3/deleteAllFromParentPrefix', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}

export const deleteObjectsByStartingPrefix = async (key) => {
    return await csrfFetch('/s3/deleteObjectsByStartingPrefix', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}