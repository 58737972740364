import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    IconButton,
    Text,
    Tooltip,
    useToast,
    Avatar
} from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { FaDownload, FaArrowLeft } from "react-icons/fa";
import { useParams, Link } from 'react-router-dom';
import { changeJobApplicationStatus, getJobApplicationForTeam } from "../API/JobApplication";
import { getFileByKey } from '../API/S3';
import { formatDateForPrint } from "../Utils/FormatDate";
import StatusBadge from '../Utils/StatusBadge';
import { jobApplicationStatus } from "../Utils/translate";
import candidateBg from "../Resources/Images/candidate_bg.jpg";

const JobApplication = () => {
    const { jobId, jobApplicationId } = useParams();
    const [jobApplication, setJobApplication] = useState();
    const [loadingCV, setLoadingCV] = useState(false);
    const [loading, setLoading] = useState(false);

    const toast = useToast();

    useEffect(() => {
        try {
            getJobApplicationForTeam(jobId, jobApplicationId, setJobApplication);
        } catch (error) {
            // console.log(error);
        }
    }, [jobId, jobApplicationId]);

    const downloadFile = async (key) => {
        try {
            const response = await getFileByKey(key);

            if (!response.ok) {
                toast({
                    title: "Erreur lors de la récupération de l'image",
                    description: error.message,
                    status: "error"
                });

                throw new Error('Erreur lors de la récupération de l\'image');
            }

            // Convertir la réponse en blob (binary data)
            const blob = await response.blob();

            // Ouvrir le blob dans un nouvel onglet
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = "_blank";
            link.click();
        } catch (error) {
            toast({
                title: "Erreur lors de la création du lien",
                status: "error"
            })
        }
    }

    const handleDownloadAttachment = async (attachment) => {
        if (attachment === "cv"){
            setLoadingCV(true);
            await downloadFile(jobApplication.cv);}
        else{
            setLoading(true);
            await downloadFile(jobApplication.cover);}
        setLoading(false);
        setLoadingCV(false);
    }

    const handleUpdateStatus = async (status) => {
        try {
            await changeJobApplicationStatus(jobApplicationId, status, setJobApplication, toast);
        } catch (error) {
            toast({
                title: "Erreur lors de la modification du statut",
                status: "error"
            })
        }
    }

    if (!jobApplication) {
        return <div></div>;
    }

    return (
        <Box p="4">
            <Flex alignItems='center' gap={4} mb={4}>
                <Button as={Link} to={`/offre-emploi/${jobApplication.jobOffer._id}`} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour à mon offre d'emploi</Button>
            </Flex>
            <Flex direction="column" >
                <Heading size="md">
                    Détails de la candidature
                </Heading>
                <Divider mt={2} mb={8} />

                <Flex direction="row" gap={4} mt={4}>
                    <Box position="sticky" top={0} flex={1} mb={4} bg="lightBg.50" borderRadius="xl" p={6} maxHeight="fit-content" >
                        <Flex gap={4} mb={4} >
                            <Button isLoading={loadingCV} size="sm" borderRadius="md" leftIcon={<FaDownload />} onClick={() => handleDownloadAttachment("cv")}>Télécharger le CV</Button>
                            <Button isLoading={loading} size="sm" borderRadius="md" leftIcon={<FaDownload />} onClick={() => handleDownloadAttachment("cover")}>Télécharger la lettre de motivation</Button>
                        </Flex>
                        <Flex bgImage={`url(${candidateBg})`} bgSize={"1500px"} bgPosition={"center center"} height={"200px"} borderRadius="xl" justifyContent={"center"} alignItems={"end"}>
                            <Avatar border={"4px solid white"} size={"xl"} name={`${jobApplication.candidate.firstname} ${jobApplication.candidate.lastname}`} src={jobApplication.candidate.blob} transform={"translateY(50%)"} />
                        </Flex>
                        <Flex pt={"60px"} direction={"column"} pb={10}>
                            <Flex gap={4} alignItems={"center"} justifyContent="space-between">
                                <Text fontSize='xl' as='b' fontWeight={"semibold"} color={"gray.700"}>{jobApplication.candidate.firstname.charAt(0).toUpperCase() + jobApplication.candidate.firstname.slice(1)} {jobApplication.candidate.lastname.toUpperCase()}</Text>
                            </Flex>
                            <Text fontSize='lg' color={"gray.700"}>{jobApplication.candidate.email}</Text>
                            <Text fontSize='lg' color={"gray.700"}>{jobApplication.phone}</Text>
                        </Flex>
                        <Flex justifyContent="center" w="100%" gap={2} mt={4}>
                            {jobApplication.status !== 'accepted' && (
                                <Button
                                    flex={1}
                                    colorScheme="brand"
                                    onClick={() => handleUpdateStatus('accepted')}
                                >
                                    Accepter
                                </Button>
                            )}
                            {jobApplication.status !== 'pending' && (
                                <Button
                                    flex={1}
                                    colorScheme="yellow"
                                    onClick={() => handleUpdateStatus('pending')}
                                >
                                    Mettre en cours d'analyse
                                </Button>
                            )}
                            {jobApplication.status !== 'rejected' && (
                                <Button
                                    flex={1}
                                    colorScheme="red"
                                    onClick={() => handleUpdateStatus('rejected')}
                                >
                                    Refuser
                                </Button>
                            )}
                        </Flex>
                    </Box>
                    <Flex flex={3} direction={"column"} gap={4} >
                        <Flex mb={4} bg="lightBg.50" borderRadius="xl" p={6} direction={"column"}>
                            <Heading size="md" mb={4} >Synthèse de la candidature</Heading>
                            <Flex gap={4} alignItems="center">
                                <Heading size="sm">
                                    Nom de l'offre d'emploi:
                                </Heading>
                                <Text>
                                    {jobApplication.jobOffer.name}
                                </Text>
                            </Flex>
                            <Flex gap={4} alignItems="center">
                                <Heading size="sm">
                                    Description de l'offre d'emploi:
                                </Heading>
                                <Text>
                                    {jobApplication.jobOffer.description}
                                </Text>
                            </Flex>
                            <Flex gap={4} alignItems="center">
                                <Heading size="sm">
                                    Statut:
                                </Heading>
                                <Text>
                                    <StatusBadge status={jobApplicationStatus[jobApplication.status]} />
                                </Text>
                            </Flex>
                            <Flex gap={4} alignItems="center">
                                <Heading size="sm">
                                    Date de candidature:
                                </Heading>
                                <Text>
                                    {formatDateForPrint(jobApplication.createdAt)}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex mb={4} bg="lightBg.50" borderRadius="xl" p={6} direction={"column"}>
                            <Heading size="md" mb={4} >Description de l'offre d'emploi</Heading>
                            <div id='joboffer' dangerouslySetInnerHTML={{ __html: jobApplication.jobOffer.content }} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default JobApplication;

