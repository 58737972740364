import {
    Box,
    Button,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    useToast
} from '@chakra-ui/react';
import { closestCenter, DndContext, DragOverlay, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { getTeamsByUser, updateCandidatStatus } from '../../API/Candidats';
import { getLoggedJobOffer } from '../../API/JobOffers';
import { fadeIn } from '../../Components/Dashboard/Animation';

const CandidatsKanban = () => {
    const [candidats, setCandidats] = useState({ "Nouveau": [], "En cours": [], "Retenu": [], "Non retenu": [] });
    const [teams, setTeams] = useState([]);
    const [jobOffers, setJobOffers] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("Tous les candidats");
    const toast = useToast();
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        loadUserData();
    }, []);

    useEffect(() => {
        if (teams.length > 0) {
            loadUserCandidats();
        }
    }, [teams]);

    useEffect(() => {
        setCandidats(prev => ({ ...prev }));
    }, [selectedFilter]);

    const loadUserData = async () => {
        try {
            const userTeams = await getTeamsByUser();
            setTeams(userTeams); 
            await getLoggedJobOffer(setJobOffers);
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Impossible de charger les données utilisateur.",
                status: "error",
            });
        }
    };

    const loadUserCandidats = async () => {
        try {
            const transformed = { "Nouveau": [], "En cours": [], "Retenu": [], "Non retenu": [] };

            teams.forEach(team => {
                team.candidate.forEach(candidate => {
                    const status = candidate.status || "Nouveau";
                    if (transformed[status]) {
                        transformed[status].push({
                            id: candidate._id,
                            firstname: candidate.firstname,
                            lastname: candidate.lastname,
                            email: candidate.email,
                            status: candidate.status,
                            phone: candidate.phone || '',
                            teamId: team._id,
                            jobsId: candidate.jobsId || [],
                        });
                    }
                });
            });

            setCandidats(transformed);
            setSelectedFilter("Tous les candidats");
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Impossible de charger les candidats.",
                status: "error",
            });
        }
    };

    const handleDragStart = ({ active }) => {
        setActiveId(active.id);
    };

    const handleDragEnd = ({ active, over }) => {
        setActiveId(null);

        if (!over) return;

        const activeColumn = Object.keys(candidats).find(status =>
            candidats[status].some(candidate => candidate.id === active.id)
        );
        const overColumn = over.id.startsWith("column-")
            ? over.id.replace("column-", "")
            : null;

        if (activeColumn && overColumn && activeColumn !== overColumn) {
            const movedCandidate = candidats[activeColumn].find(item => item.id === active.id);

            try {
                updateCandidatStatus(movedCandidate.id, overColumn, (newStatus) => {
                    setCandidats((prev) => {
                        const activeCandidates = prev[activeColumn].filter(item => item.id !== active.id);
                        const targetColumn = prev[overColumn] || [];

                        return {
                            ...prev,
                            [activeColumn]: activeCandidates,
                            [overColumn]: [...targetColumn, { ...movedCandidate, status: newStatus }],
                        };
                    });
                    toast({
                        title: "Statut mis à jour avec succès",
                        status: "success",
                    });
                });
            } catch (error) {
                toast({
                    title: "Erreur",
                    description: "Impossible de mettre à jour le statut du candidat.",
                    status: "error",
                });
            }
        }
    };

    const handleFilter = (filterType, filterId, filterName) => {
        setSelectedFilter(filterName);

        if (filterType === "all") {
            loadUserCandidats(); 
        } else {
            const filtered = { "Nouveau": [], "En cours": [], "Non retenu": [], "Retenu": [] };

            teams.forEach(team => {
                team.candidate.forEach(candidate => {
                    const status = candidate.status || "Nouveau";

                    const candidateWithId = {
                        ...candidate,
                        id: candidate._id || candidate.id, 
                    };

                    if (filterType === "team" && team._id === filterId) {
                        if (filtered[status]) {
                            filtered[status].push(candidateWithId);
                        }
                    } else if (filterType === "job" && candidate.jobsId.includes(filterId)) {
                        if (filtered[status]) {
                            filtered[status].push(candidateWithId);
                        }
                    }
                });
            });
            setCandidats(filtered);
        }
    };

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >

            <Flex justify="space-between" align="center" mb={4}>
                <Box></Box>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />} colorScheme="brand" variant={"ghost"} >
                        {selectedFilter}
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => handleFilter("all", null, "Tous les candidats")}>
                            Tous les candidats
                        </MenuItem>
                        <MenuGroup title="Vos équipes">
                            {teams.map(team => (
                                <MenuItem
                                    key={team._id}
                                    onClick={() => handleFilter("team", team._id, team.name)}
                                >
                                    {team.name}
                                </MenuItem>
                            ))}
                        </MenuGroup>
                        <MenuGroup title="Offres d'emploi">
                            {jobOffers.map(job => (
                                <MenuItem
                                    key={job._id}
                                    onClick={() => handleFilter("job", job._id, job.name)}
                                >
                                    {job.name}
                                </MenuItem>
                            ))}
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </Flex>

            <Flex gap={8} animation={`${fadeIn} 0.4s ease-in`}>
                {["Nouveau", "En cours", "Retenu", "Non retenu"].map(status => (
                    <Column key={`${status}-${candidats[status]?.length}`} id={status} title={status} items={candidats[status]} selectedFilter={selectedFilter} />
                ))}
            </Flex>

            <DragOverlay>
                {activeId && (
                    Object.values(candidats)
                        .flat()
                        .map(candidate => candidate.id === activeId && (
                            <DraggableItem
                                key={candidate.id}
                                id={candidate.id}
                                name={`${candidate.firstname} ${candidate.lastname}`}
                                email={candidate.email}
                            />
                        ))
                )}
            </DragOverlay>
        </DndContext>
    );
};

const Column = ({ id, title, items }) => {
    const { setNodeRef } = useDroppable({ id: `column-${id}` });

    const COLUMN_COLORS = {
        "Nouveau": "#007BFF",
        "En cours": "#FFC107",
        "Retenu": "#28A745",
        "Non retenu": "#DC3545",
    };

    const backgroundColor = `${COLUMN_COLORS[id]}33`;
    const headerBackgroundColor = COLUMN_COLORS[id];

    return (
        <Box
            ref={setNodeRef}
            flex={1}
            bg={backgroundColor}
            p={0}
            borderRadius="md"
            data-column-id={`column-${id}`}
            overflow="hidden"
            minH={"500px"}
            boxShadow={"sm"}
        >
            <Box
                bg={headerBackgroundColor}
                color="white"
                textAlign="center"
                py={2}
                fontWeight="bold"
            >
                {title}
            </Box>

            <Box p={4}>
                <SortableContext
                    key={`sortable-${id}`}
                    items={(items || []).map(item => item.id)}
                >
                    {
                        items.map(candidate => (
                            <DraggableItem
                                key={candidate.id}
                                id={candidate.id}
                                name={`${candidate.firstname} ${candidate.lastname}`}
                                email={candidate.email}
                            />
                        ))
                    }
                </SortableContext>
            </Box>
        </Box>
    );
};

const DraggableItem = ({ id, name, email }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const navigate = useNavigate();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: "10px",
        marginBottom: "8px",
        background: "white",
        border: "1px dashed #E2E8F0",
        borderRadius: "5px",
        cursor: "grab",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };

    const handleNavigate = (e) => {
        e.stopPropagation();
        navigate(`/candidat/${id}`);
    };

    return (
        <Box
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <Box>
                <strong>{name}</strong>
                <p style={{ margin: 0, fontSize: "12px", color: "#888" }}>{email}</p>
            </Box>
            <IconButton
                aria-label="Voir le profil du candidat"
                icon={<FaPersonCircleQuestion />}
                size="sm"
                variant="outline"
                onClick={handleNavigate}
                borderRadius="full"
                colorScheme="brand"
                onPointerDown={(e) => e.stopPropagation()}
            />
        </Box>
    );
};

export default CandidatsKanban;
