import React from 'react';
import { Flex, Text, Badge } from '@chakra-ui/react';
import { MdOutlineAutoAwesome } from 'react-icons/md';

const AITag = () => {
    return (
        <Flex mt={-1} ml={1} mr={1} alignItems="center">
            <Badge
                variant="subtle"
                style={{
                    background: 'linear-gradient(to right, #4FD1C5, #2C7A7B)',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                AI
                <MdOutlineAutoAwesome color='white' />
            </Badge>
        </Flex>
    );
};

export default AITag;