import React from 'react'
import { Checkbox, Code, Flex, FormLabel, FormControl, Radio } from '@chakra-ui/react'
export default function CheckboxComponent({ responses, responsesChecked, handleChange, questionIndex, type }) {
    return (
        <>
            {responses.map((res, index) => {
                return (
                    <FormControl key={index} display='flex' gap={8}>
                        <Checkbox isChecked={responsesChecked[questionIndex] && responsesChecked[questionIndex].includes(index)} onChange={(e) => handleChange(questionIndex, index, e.target.checked, type)} />
                        <FormLabel key={index} fontSize='md'><Code bg="lightBg.50">{res}</Code></FormLabel>
                    </FormControl>
                )
            })}
        </>

    )
}

