import { Button, ButtonGroup, Flex, IconButton, Image, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, useToast } from '@chakra-ui/react'; // Ou selon ton UI framework
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { getImageFromS3 } from "../Utils/ImageFromS3";

const QuestionImage = ({ question }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [showPopOver, setShowPopOver] = useState(false);
    const [imageChangeCounter, setImageChangeCounter] = useState(0);
    const toast = useToast();

    const getImageFromS3Func = async (questionimage) => {
        const pictureBlob = await getImageFromS3(questionimage)
        if (!pictureBlob) {
            return
        }
        const url = URL.createObjectURL(pictureBlob)
        return url
    }

    const deleteImageButtonHandler = async () => {
        if (imageUrl && !question.tmp) {
            question.image = null
        } else if (!imageUrl && question.tmp) {
            question.tmp = null
            question.image = null
        }
        setImageUrl(null)
        setShowPopOver(false)
        toast({
            title: 'L\'image à bien été supprimée',
            status: 'success',
        })
    }

    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (question.image && !question.tmp) {
                    if (imageChangeCounter === 0) {
                        const url = await getImageFromS3Func(question.image);
                        setImageUrl(url); // Mettre à jour l'état avec l'URL de l'image
                        setImageChangeCounter(imageChangeCounter + 1)
                        return;
                    }
                }
                setImageUrl(null);
            } catch (error) {
                setImageUrl(null);
                toast({
                    title: 'Erreur lors du téléchargement de l\'image',
                    status: 'error',
                })
            }
        };

        fetchImage();
    }, [question.image, question.tmp]); // L'effet se déclenche quand `question.image` ou `question.tmp` change

    return (
        <>
            {((imageUrl && !question.tmp) || (!imageUrl && question.tmp)) && <Flex>
                <Image
                    width={"100%"}
                    maxHeight={"500px"}
                    objectFit="contain"
                    src={imageUrl && !question.tmp ? imageUrl : question.tmp}
                    mb={4}
                />
                <Popover
                    placement='right'
                    isOpen={showPopOver}
                    closeOnBlur={true}
                    onClose={() => setShowPopOver(false)}
                >
                    <PopoverTrigger>
                        <IconButton
                            rounded="full"
                            variant="ghost"
                            colorScheme='red'
                            aria-label={"Delete image"}
                            icon={<FaTrash />}
                            onClick={() => setShowPopOver(true)}
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader>
                            Supprimer Image
                        </PopoverHeader>
                        <PopoverCloseButton onClick={() => setShowPopOver(false)} />
                        <PopoverBody>
                            Voulez-vous supprimer cette image du questionnaire ?
                        </PopoverBody>
                        <PopoverFooter
                            border='0'
                            display='flex'
                            alignItems='center'
                            justifyContent='right'
                        >
                            <ButtonGroup size='sm'>
                                <Button
                                    colorScheme='red'
                                    onClick={() => deleteImageButtonHandler()}
                                >
                                    Supprimer l'image
                                </Button>
                                <Button onClick={() => setShowPopOver(false)}>
                                    retour
                                </Button>
                            </ButtonGroup>
                        </PopoverFooter>
                    </PopoverContent>
                </Popover>
            </Flex>}
        </>
    );
};

export default QuestionImage;