import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, FormLabel, Input, Spacer } from '@chakra-ui/react'
import React from 'react'
import { addOpenInvitationLink } from "../../../API/Invitations";
import { useToast } from "@chakra-ui/react";

const AddInvitationLink = ({ isOpen, onClose, cancelRef, invitationLinks, setInvitationLinks, jobOfferId }) => {
    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        const endDate = new Date(data.get('endDate'))
        const nbMax = parseInt(data.get('nbMax'))
        const response = await addOpenInvitationLink(jobOfferId, endDate, nbMax);
        if (!response.ok) {
            toast({
                title: 'Une erreur est survenue',
                status: 'error',
            })
            return;
        }

        toast({
            title: 'Lien d\'invitation crée',
        })
        e.target.reset();
        onClose()
        const link = await response.json()
        setInvitationLinks([...invitationLinks, link])
    }
    return (
        <AlertDialog
            size={"xl"}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Créer un lien d'invitation
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Box mt={4}>
                            <form onSubmit={handleSubmit} id="invitationForm">
                                <FormLabel htmlFor="endDate">Date de fin</FormLabel>
                                <Input type="datetime-local" name="endDate" id="endDate" placeholder="Date de fin" mb={4} />
                                <FormLabel htmlFor="nbMax">Nombre max d'utilisation</FormLabel>
                                <Input type="number" name="nbMax" id="nbMax" placeholder="Nombre max d'utilisation" mb={4} />
                            </form>
                        </Box>
                        <AlertDialogFooter px={0} gap={2}>
                            <Button ref={cancelRef} onClick={() => {
                                onClose()
                            }}>
                                Fermer
                            </Button>
                            <Button type="submit" colorScheme="brand" form="invitationForm">
                                Créer le lien d'invitation
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default AddInvitationLink 