import csrfFetch from '../Utils/CSRF';

export const getLoggedJobOffer = async (setData) => {
    const response = await csrfFetch('/joboffers');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    }
}


export const getJobOfferById = async (jobOfferId) => {
    const response = await csrfFetch("/joboffers/" + jobOfferId)
    const data = await response.json();

    return data;
}

export const getJobOfferByIdWithCandidates = async (jobOfferId) => {
    const response = await csrfFetch(`/joboffers/${jobOfferId}/withCandidates`)
    const data = await response.json();

    return data;
}

export const linkJobOfferToCandidate = async (jobOfferId, candidateId) => {
    const response = await csrfFetch(`/users/candidat/${candidateId}/addOrDeleteJobOffer/${jobOfferId}`, {
        method: 'PUT',
    })

    return response.status
}

export const createJobOffer = async (id, jobOffer) => {
    if (jobOffer.team && typeof jobOffer.team === 'object') {
        jobOffer.team = jobOffer.team._id;
    }

    const response = await csrfFetch(`/joboffers${id ? "/" + id : ""}`, {
        method: id ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobOffer)
    })


    return response;
}

export const removeJobOffer = async (jobOfferId) => {
    const response = await csrfFetch("/joboffers/" + jobOfferId, {
        method: 'DELETE'
    })

    return response.ok ? true : false;
}

export const getJobOffersByTeamId = async (teamId) => {
    const response = await csrfFetch(`/joboffers/team/${teamId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        return data
    } else {
        return [];
    }
}

export const addOrDeleteQuiz = async (jobOfferId, quizId) => {
    return await csrfFetch(`/joboffers/${jobOfferId}/quiz/${quizId}`, {
        method: 'PUT',
    });
}

export const getPublicJobOffers = async () => {
    const response = await csrfFetch('/joboffers/active-offers');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        return data
    } else {
        return [];
    }
}

export const getJobOffersByUserTeams = async (setData) => {
    const response = await csrfFetch(`/joboffers/teams`);

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    } else {
        setData([]);
    }

}