import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getPendingInvitations } from '../../API/Invitations';
import { fadeIn } from '../../Components/Dashboard/Animation';
import InvitationsTable from '../../Components/InvitationsTable';

const CandidatsInvitations = () => {
    const [invitations, setInvitations] = useState([])

    useEffect(() => {
        loadInvitations()
    }, [])

    const loadInvitations = async () => {
        try {
            const data = await getPendingInvitations();
            setInvitations(data)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des invitations',
                status: 'error',
            })
        }
    }

    return (
        <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`}>
            <Flex gap={4} justifyContent={"space-between"} alignItems={"center"}>
                <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Vos invitations en attente</Heading>
                <Flex gap={4}>
                </Flex>
            </Flex>
            <Box w='100%' borderRadius={"md"}>
                <InvitationsTable invitations={invitations} reload={loadInvitations} />
            </Box>
        </Flex>)
}

export default CandidatsInvitations