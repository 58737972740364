import { Button, Flex, FormControl, FormLabel, Grid, Heading, Input, Text, useToast, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import { getCandidateApplicationByJobId, postJobApplication } from "../../API/JobApplication";
import FileUploaderComponent from '../FileUploaderComponent';
import { UserContext } from '../../Authentication/ProtectedRoute';


const JobApplication = ({ job }) => {
    const { user, loadData } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (user.phone) {
            handleChange("phone", user.phone);
        }
    }, [user.phone]);

    const [application, setApplication] = useState({
        phone: "",
        cover: "",
        cv: ""
    });
    const [alreadyApplied, setAlreadyApplied] = useState(false);
    const toast = useToast();

    useEffect(() => {
        if (job) {
            getCandidateApplicationByJobId(job._id)
                .then(data => setAlreadyApplied(data));
        }
    }, [job])

    const handleChange = (key, value) => {
        setApplication({ ...application, [key]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('phone', application.phone);
        formData.append('cover', application.cover);
        formData.append('cv', application.cv);

        const respose = await postJobApplication(job._id, formData);

        if (!respose.ok) {
            toast({
                title: "Erreur lors de l'envoi de la candidature",
                status: "error"
            })

            return;
        }

        toast({
            title: "Candidature envoyée",
        })

        setApplication({
            phone: "",
            cover: "",
            cv: ""
        });

        setLoading(false);

        setAlreadyApplied(true);
    }

    return (
        <Flex direction={"column"} gap={4} >
            {
                alreadyApplied ? (
                    <Text fontSize="lg" color="green.500">
                        Votre candidature à bien été envoyée
                    </Text>
                ) : loading ? (
                    <Text fontSize="lg" color="green.500">
                        <Spinner mr={2} /> Votre candidature est en cours d'envoi à l'entreprise
                    </Text>
                ) : (
                    <>
                        <Flex direction={"column"}>
                            <Heading as="h2" size="md" mb={0} pb={0}>
                                Postuler à l'offre
                            </Heading>
                            <Text fontSize="sm" color="gray.500" marginBottom={4}>
                                Votre nom, prénom et email liés à votre compte Aptitude seront transmis à l'entreprise.
                            </Text>
                        </Flex>
                        <FormControl>
                            <FormLabel>Numéro de téléphone</FormLabel>
                            <Input
                                bg="white"
                                value={application.phone}
                                type="tel"
                                placeholder="Entrez votre numéro de téléphone"
                                onChange={(e) => handleChange("phone", e.target.value)}
                                required
                            />
                        </FormControl>
                        <Grid templateColumns={"repeat(2, 1fr)"} gap={4} wrap="wrap">
                            <FormControl>
                                <FormLabel>Lettre de motivation (PDF ou Word)</FormLabel>
                                <FileUploaderComponent
                                    handleImageRemove={() => handleChange("cover", "")}
                                    file={application.cover}
                                    questionIndex={"cover"}
                                    handleImageChange={handleChange}
                                    phrase="votre lettre de motivation"
                                    accept=".pdf,.doc,.docx"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Curriculum Vitae (PDF ou Word)</FormLabel>
                                <FileUploaderComponent
                                    handleImageRemove={() => handleChange("cv", "")}
                                    file={application.cv}
                                    questionIndex={"cv"}
                                    handleImageChange={handleChange}
                                    phrase="votre CV"
                                    accept=".pdf,.doc,.docx"
                                />
                            </FormControl>
                        </Grid>
                        <Button isLoading={loading} onClick={handleSubmit} colorScheme="brand" marginTop="4">
                            Postuler
                        </Button>
                    </>
                )
            }
        </Flex>
    );
};

export default JobApplication;

