import { ChakraProvider } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from './Authentication/ProtectedRoute';
import CandidatComponent from './Routes/CandidatComponent';
import JobSearchComponent from './Routes/CandidatInterface/JobSearchComponent';
import CandidatQuizExam from './Routes/CandidatQuizExam';
import CandidatRegistrationComponent from './Routes/CandidatRegistrationComponent';
import CandidatsComponent from './Routes/CandidatsComponent';
import GoogleAuth from './Routes/GoogleAuth';
import JobApplication from './Routes/JobApplication';
import JobOfferComponent from './Routes/JobOfferComponent';
import JobOffersComponent from './Routes/JobOffersComponent';
import JobOfferUpdateComponent from './Routes/JobOfferUpdateComponent';
import JobSearchInfosComponent from './Routes/JobSearchInfosComponent';
import JoinTeamComponent from './Routes/JoinTeamComponent';
import LoggedHomepage from './Routes/LoggedHomepage';
import LoginComponent from './Routes/LoginComponent';
import MentionLegalesComponent from './Routes/MentionsLegalesComponent';
import NotFoundComponent from './Routes/NotFoundComponent';
import ProfileComponent from './Routes/ProfileComponent';
import QuizComponent from './Routes/QuizComponent';
import QuizResultsComponent from './Routes/QuizResultsComponent';
import QuizUpdateComponent from './Routes/QuizUpdateComponent';
import QuizzesComponent from './Routes/QuizzesComponent';
import { extendTheme } from '@chakra-ui/react';
import "./index.css";
import PasswordReset from './Routes/PasswordReset';
import InvitationOffer from './Routes/InvitationOffer';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginComponent />
  },
  {
    path: "/accept-invitation/:invitationId",
    element: <CandidatRegistrationComponent />
  },
  {
    path: "/invitation-offre/:invitationId",
    element: <InvitationOffer />
  },
  {
    path: "/join-team/:invitationId",
    element: <ProtectedRoute component={JoinTeamComponent} />
  },
  {
    path: "/offre-emploi",
    element: <ProtectedRoute component={JobOffersComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/offre-emploi/:id",
    element: <ProtectedRoute component={JobOfferComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/offre-emploi/:jobId/candidature/:jobApplicationId",
    element: <ProtectedRoute component={JobApplication} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/offre-emploi/update/:id",
    element: <ProtectedRoute component={JobOfferUpdateComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/offres-emploi",
    element: <ProtectedRoute component={JobSearchComponent} roles={["candidate"]} />
  },
  {
    path: "/offres-emploi/:jobId",
    element: <ProtectedRoute component={JobSearchInfosComponent} roles={["candidate", "recruiter", "administrator"]} />
  },
  {
    path: "/exam/:runningQuizId",
    element: <ProtectedRoute component={CandidatQuizExam} roles={["candidate"]} />
  },
  {
    path: "/exam/responses/:quizId",
    element: <ProtectedRoute component={QuizResultsComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/questionnaires",
    element: <ProtectedRoute component={QuizzesComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/questionnaire/:id",
    element: <ProtectedRoute component={QuizComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/questionnaire/nouveau",
    element: <ProtectedRoute component={QuizUpdateComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/questionnaire/update/:id",
    element: <ProtectedRoute component={QuizUpdateComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/candidats",
    element: <ProtectedRoute component={CandidatsComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/candidat/:candidatId",
    element: <ProtectedRoute component={CandidatComponent} roles={["recruiter", "administrator"]} />
  },
  {
    path: "/profil",
    element: <ProtectedRoute component={ProfileComponent} />
  },
  {
    path: "/",
    element: <ProtectedRoute component={LoggedHomepage} />
  },
  {
    path: "/mentions-legales",
    element: <MentionLegalesComponent />
  },
  {
    path: "/mot-de-passe-oublie",
    element: <PasswordReset />
  },
  {
    path: "/mot-de-passe-oublie/:passwordInvitation",
    element: <PasswordReset />
  },
  {
    path: "/auth/google",
    element: <GoogleAuth />
  },
  {
    path: "*",
    element: <NotFoundComponent />
  }
]);

const theme = extendTheme({
  colors: {
    brand: {
      50: "#E3F9F1",
      100: "#B7ECD9",
      200: "#88DEBF",
      300: "#57D1A4",
      400: "#2DC48C",
      500: "#03B97C", // couleur principale
      600: "#039A69",
      700: "#027A55",
      800: "#015C42",
      900: "#013F2D",
    },
    lightBg: {
      50: "#f5f7f66c",  // Base (.50)
      100: "#f5f7f680", // Légèrement plus opaque
      200: "#f5f7f699", // Encore plus opaque
      300: "#f5f7f6b3", // Presque opaque
      400: "#f5f7f6cc", // Teinte plus prononcée
      500: "#f5f7f6e6", // Plus proche de l'opaque complet
      600: "#f5f7f6f2", // Presque entièrement opaque
      700: "#e1e3e4",   // Version sans transparence, plus foncée
      800: "#d1d3d4",   // Variation plus foncée
      900: "#c1c3c4"    // Variation encore plus sombre
    }
  },
})

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Injecter le script HubSpot
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/48629553.js'; // Remplace XXXXXXX par ton ID HubSpot
      document.body.appendChild(script);

      return () => {
        // Nettoyage si nécessaire
        document.body.removeChild(script);
      };
    }
  }, []);

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: "top", duration: 9000, isClosable: true, status: "success" } }}>
    <App />
  </ChakraProvider>
);