import { Box, CircularProgress, CircularProgressLabel, Flex, Tag, Text } from '@chakra-ui/react'

import React from 'react'

const ExamResultHeaderComponent = ({ name, description, tags, _id, questions, good, bad }) => {


    return (
        <Box w='100%' boxShadow={"md"} borderRadius="md" p={4} pt={2} mb={8} color="gray.700" bg={"lightBg.600"}>
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='4xl'>{name}</Text>
                <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag>
                <Flex ml="auto" gap={1} direction={"column"}>
                    <Flex alignItems={"center"} gap={4}>
                        <Text as="b" color={"gray.600"}>Note : {good + " sur " + questions.length}</Text>
                        <CircularProgress value={(good / questions.length) * 100} color='green.400'>
                            <CircularProgressLabel>{Math.round((good / questions.length) * 100)}%</CircularProgressLabel>
                        </CircularProgress>
                    </Flex>
                </Flex>
            </Flex>
            <Flex alignItems="baseline" gap={4}>
                <Text fontSize='md'>{description}</Text>
            </Flex>
            <Flex alignItems="baseline" gap={1} mt={4}>
                {tags && tags.map((tag, index) => (
                    <Tag fontSize='xs' key={index} variant='solid' colorScheme="brand">{tag}</Tag>
                ))}

            </Flex>
        </Box>
    )
}

export default ExamResultHeaderComponent