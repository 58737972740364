import { Box, Flex, IconButton, Input, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCheck, FaDownload, FaEye, FaFilePdf, FaFileWord, FaImage, FaPen, FaTrash, FaXmark, FaFile } from 'react-icons/fa6'
import DeleteAttachmentDialog from './Dialog/DeleteAttachmentDialog'
import ViewAttachmentModel from './ViewAttachmentModel';
import { editAttachement } from '../../API/Attachements';
import { getFileByKey } from "../../API/S3";

const allowedToViewExtensions = [
    'png',
    'jpeg',
    'jpg',
    'gif',
    'bmp',
    'tiff',
    'webp',
    'pdf'
];

const AttachmentList = ({ attachments, reload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure();
    const [attachmentToDelete, setAttachmentToDelete] = useState(null)
    const [attachmentToUpdate, setAttachmentToUpdate] = useState(null)
    const [attachmentToView, setAttachmentToView] = useState(null)
    const toast = useToast();

    const handleEditAttachment = async () => {
        try {
            const response = await editAttachement(attachmentToUpdate)

            if (!response.ok) {
                toast({
                    title: "Erreur",
                    description: "La modification a échouée !",
                    status: "error"
                });
                throw new Error('Failed to edit attachment');
            } else {
                toast({
                    title: "Succès",
                    description: "La pièce jointe a bien été renommée !",
                    status: "success"
                });

                setAttachmentToUpdate(null)
                reload()
            }
        } catch (error) {
            toast({
                title: "Erreur",
                description: "La modification a échouée !",
                status: "error"
            });
        }
    }

    const handleViewAttachment = async (attachment) => {
        try {
            // Requête GET pour récupérer l'image depuis le backend
            const response = await getFileByKey(attachment.filePath)

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération de l\'image');
            }

            // Convertir la réponse en blob (binary data)
            const imageBlob = await response.blob();

            // Crée une URL temporaire à partir du blob pour afficher l'image
            const imageObjectURL = URL.createObjectURL(imageBlob);

            if (attachment.filePath.endsWith('.pdf')) {
                window.open(imageObjectURL, '_blank');
            } else {
                setAttachmentToView({ ...attachment, imageUrl: imageObjectURL })
                onOpenView();
            }
        } catch (error) {
            toast({
                title: "Erreur lors de la récupération de l'image",
                status: "error"
            });
        }
    }

    const handleDownloadAttachment = async (attachment) => {
        try {
            const response = await getFileByKey(attachment.filePath);

            if (!response.ok) {
                toast({
                    title: "Erreur lors de la récupération de l'image",
                    description: error.message,
                    status: "error"
                });

                throw new Error('Erreur lors de la récupération de l\'image');
            }

            // Convertir la réponse en blob (binary data)
            const imageBlob = await response.blob();

            // Télécharger le blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(imageBlob);
            link.download = attachment.name;
            link.click();

            toast({
                title: "Téléchargement en cours",
                description: "Le téléchargement de l'attachement va démarrer.",
                status: "info"
            });
        } catch (error) {
            toast({
                title: "Erreur lors du téchargement du fichier.",
                status: "error"
            })
        }
    }

    const getLogoFromExtension = (extension) => {
        switch (extension) {
            case 'pdf':
                return <Flex
                    p={4}
                    alignItems={"center"}
                    borderRadius={"md"}
                    justifyContent={"center"}
                    bg={`linear-gradient(135deg, #D69E2E 0%, #805AD5 100%)`}
                ><FaFilePdf color='white' size={20} /></Flex>
            case 'png':
                return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #805AD5 0%, #086F83 100%)`}>
                    <FaImage color='white' size={20} />
                </Flex>
            case 'jpg':
            case 'jpeg':
                return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #3182ce 0%, #805AD5 100%)`}><FaImage color='white' size={20} /></Flex>
            case 'doc':
                return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #3182ce 0%, #805AD5 100%)`}><FaFileWord color='white' size={20} /></Flex>
            case 'docx':
                return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #D53F8C 0%, #086F83 100%)`}><FaFileWord color='white' size={20} /></Flex>
            default:
                return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #D53F8C 0%, #086F83 100%)`}><FaFile color='white' size={20} /></Flex>
        }
    }

    return (
        <>
            <Flex direction={"column"} flex={1}>
                {
                    attachments && attachments.map((attachment) => (
                        <Box key={attachment._id}>
                            <Flex
                                alignItems={"center"}
                                gap={1}
                            >
                                {
                                    attachmentToUpdate && attachmentToUpdate._id === attachment._id ? (<>
                                        <Flex gap={1} flex={1}>
                                            <Input flex={3} size={"sm"} borderRadius={4} value={attachmentToUpdate.name.split('.').slice(0, -1).join('.')} onChange={(e) => setAttachmentToUpdate({ ...attachment, name: e.target.value + '.' + attachmentToUpdate.name.split('.').pop() })} />
                                            <Input flex={1} size={"sm"} disabled borderRadius={4} value={'.' + attachment.filePath.split('.').pop()} />
                                        </Flex>
                                        <Flex gap={1} ml="auto">
                                            <IconButton rounded={"full"} size={"sm"} aria-label='Annuler' icon={<FaXmark />} onClick={() => setAttachmentToUpdate(null)} />
                                            <IconButton rounded={"full"} size={"sm"} aria-label='Modifier' icon={<FaCheck />} onClick={handleEditAttachment} />
                                        </Flex>
                                    </>) :
                                        (<Flex border={"1px solid #EDF2F7"} borderRadius={"xl"} flex={1} p={4} alignItems={"center"} className='secondary-gradient' direction={["column", "row"]} gap={4}>
                                            {getLogoFromExtension(attachment.filePath.split('.').pop())}
                                            <Text>{attachment.name}</Text>
                                            <Flex gap={1} ml="auto">
                                                {allowedToViewExtensions.includes(attachment.filePath.split('.').pop()) && (
                                                    <Tooltip label='Visualiser' placement='top'>
                                                        <IconButton rounded={"full"} size={"sm"} bg={"white"} aria-label='Visualiser' icon={<FaEye />} onClick={() => handleViewAttachment(attachment)} />
                                                    </Tooltip>
                                                )}
                                                <Tooltip label='Télécharger' placement='top'>
                                                    <IconButton rounded={"full"} size={"sm"} bg={"white"} aria-label='Télécharger' icon={<FaDownload />} onClick={() => handleDownloadAttachment(attachment)} />
                                                </Tooltip>
                                                <Tooltip label='Renommer' placement='top'>
                                                    <IconButton rounded={"full"} size={"sm"} bg={"white"} aria-label='Éditer' icon={<FaPen />} onClick={() => setAttachmentToUpdate(attachment)} />
                                                </Tooltip>
                                                <Tooltip label='Supprimer' placement='top'>
                                                    <IconButton rounded={"full"} size={"sm"} colorScheme='red' aria-label='Supprimer' icon={<FaTrash />} onClick={() => {
                                                        setAttachmentToDelete(attachment)
                                                        onOpen()
                                                    }} />
                                                </Tooltip>
                                            </Flex>
                                        </Flex>)
                                }
                            </Flex>
                        </Box>
                    ))
                }
            </Flex>
            <DeleteAttachmentDialog isOpen={isOpen} onClose={() => {
                setAttachmentToDelete(null)
                reload()
                onClose()
            }} attachment={attachmentToDelete} />
            <ViewAttachmentModel isOpen={isOpenView} onClose={onCloseView} finalRef={onCloseView} attachment={attachmentToView} />
        </>
    )
}

export default AttachmentList
