import { Badge, Button, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
import { getTeamsByUser } from '../API/Candidats'; // Vos API
import { getLoggedJobOffer } from '../API/JobOffers';
import { UserContext } from '../Authentication/ProtectedRoute'; // Contexte utilisateur
import CandidatsCreation from '../Components/CandidatsCreation';
import CandidatsBigTable from './CandidatsComponentTabs/CandidatsBigTable'; // Composant Table
import CandidatsInvitations from './CandidatsComponentTabs/CandidatsInvitations';
import CandidatsKanban from './CandidatsComponentTabs/CandidatsKanban'; // Composant Kanban

const CandidatsComponent = () => {
    const [teams, setTeams] = useState([]);
    const [jobOffers, setJobOffers] = useState([]);
    const [isCandidatCreationVisible, setIsCandidatCreationVisible] = useState(false);
    const toast = useToast();
    const { user } = useContext(UserContext);

    // Charger les données utilisateur
    useEffect(() => {
        loadUserData();
    }, []);

    const loadUserData = async () => {
        try {
            const userTeams = await getTeamsByUser();
            setTeams(userTeams); // Gère le chargement des candidats via useEffect
            await getLoggedJobOffer(setJobOffers);
        } catch (error) {
            toast({
                title: "Erreur",
                description: "Impossible de charger les données utilisateur.",
                status: "error",
            });
        }
    };

    return (
        <>
            <Flex direction="column" gap={6}>
                <Flex gap={4} align={"center"}>
                    <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Gestion des Candidats</Heading>
                    <Button
                        leftIcon={<FaPlusSquare />}
                        onClick={() => setIsCandidatCreationVisible(true)}
                        size="sm"
                        colorScheme="brand"
                        variant="ghost"
                    >
                        Inviter un candidat
                    </Button>
                </Flex>

                <Tabs variant="soft-rounded" colorScheme="brand">
                    <TabList pl={4}>
                        <Tab>Vue Tableau</Tab>
                        <Tab display={"flex"} gap={2} alignItems={"center"}>
                            <Text>Vue Kanban</Text>
                            <Badge
                                variant="subtle"
                                style={{
                                    background: 'linear-gradient(to right, #48bb78, #2f855a)',
                                    color: 'white',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    fontWeight: 'bold',
                                    fontSize: '10px',
                                }}
                            >
                                Bêta
                            </Badge>
                        </Tab>
                        <Tab>Vos Invitations</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {/* Onglet Tableau */}
                            <CandidatsBigTable teams={teams} jobOffers={jobOffers} />
                        </TabPanel>
                        <TabPanel>
                            {/* Onglet Kanban */}
                            <CandidatsKanban teams={teams} jobOffers={jobOffers} />
                        </TabPanel>
                        <TabPanel>
                            {/* Onglet Invitations */}
                            <CandidatsInvitations />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>

            <CandidatsCreation
                isVisible={isCandidatCreationVisible}
                teams={user?.teams || []}
                cancel={() => setIsCandidatCreationVisible(false)}
                reload={loadUserData}
            />
        </>
    );
};

export default CandidatsComponent;
