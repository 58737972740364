import { getFileByKey, deleteFileByKey, deleteAllFromParentPrefix, deleteObjectsByStartingPrefix } from "../API/S3";

export const getImageFromS3 = async (imageKey) => {
    if (imageKey === null || imageKey === undefined) return null;
    if (imageKey.charAt(0) == "/") imageKey = imageKey.substr(1);

    try {
        const response = await getFileByKey(imageKey);

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération de l\'image');
        }

        // Convertir la réponse en blob (binary data)
        const imageBlob = await response.blob();
        return imageBlob
    } catch (error) {
        console.error("Erreur lors de la récupération de l'image:", error);
        return null;
    }
}

export const deleteImageFromS3 = async (imageKey) => {
    if (imageKey === null || imageKey === undefined) return null;
    if (imageKey.charAt(0) == "/") imageKey = imageKey.substr(1);

    try {
        const response = await deleteFileByKey(imageKey);

        if (!response.ok) {
            throw new Error('Erreur lors de la suppression de l\'image');
        } else {
            return true
        }
    } catch (error) {
        console.error("Erreur lors de la suppression de l'image:", error);
        return null;
    }
}

export const deleteObjectsFromParentS3Prefix = async (imageKey) => {
    if (imageKey === null || imageKey === undefined) return null;
    if (imageKey.charAt(0) == "/") imageKey = imageKey.substr(1);

    try {
        const response = await deleteAllFromParentPrefix(imageKey);

        if (!response.ok) {
            throw new Error('Erreur lors de la suppression depuis le prefix parent du S3');
        } else {
            return true
        }
    } catch (error) {
        console.error("Erreur lors de la suppression depuis le prefix parent du S3 :", error);
        return null;
    }
}

export const deleteObjectsByStartingS3Prefix = async (imageKey) => {
    if (imageKey === null || imageKey === undefined) return null;
    if (imageKey.charAt(0) == "/") imageKey = imageKey.substr(1);

    try {
        const response = await deleteObjectsByStartingPrefix(imageKey);

        if (!response.ok) {
            throw new Error(`Erreur lors de la suppression depuis le prefix commençant par ${imageKey}`);
        } else {
            return true
        }
    } catch (error) {
        console.error(`Erreur lors de la suppression depuis le prefix commençant par ${imageKey} :`, error);
        return null;
    }
}