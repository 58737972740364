import React from 'react'
import { Radio, Code, Flex, FormLabel, FormControl } from '@chakra-ui/react'
export default function RadioComponent({ responses, responsesChecked, handleChange, questionIndex, type }) {
    return (
        <>
            {responses.map((res, index) => {
                return (
                    <FormControl display='flex' key={index} gap={8}>
                        <Radio isChecked={responsesChecked[questionIndex] && responsesChecked[questionIndex].includes(index)} onChange={(e) => handleChange(questionIndex, index, e.target.checked, type)} />
                        <FormLabel key={index} fontSize='md'><Code bg="lightBg.50">{res}</Code></FormLabel>
                    </FormControl>
                )
            })}
        </>
    )
}

