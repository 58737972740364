import { Image, Text } from '@chakra-ui/react'
import React from 'react'
import AllJobs from "../Resources/Images/waiting_quizz.svg";


const HomepageEmptyWork = () => {
    return (
        <>
            <Image src={AllJobs} alt="All work" maxHeight={300} />
            <Text textAlign={"center"} fontSize={20} mt={10} color={"gray.600"}>Bravo tous vos candidats ont du travail !</Text>
        </>
    )
}

export default HomepageEmptyWork