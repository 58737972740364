import React from 'react'
import { Box, Image, Flex } from '@chakra-ui/react'
import AptitudeLogo from '../Resources/Logos/aptitude_recrutement.svg';

const PhoneHeader = () => {
    return (
        <Flex align="center" w="100%" h="75px" pl={4}>
            <Image src={AptitudeLogo} h="50%" />
        </Flex >
    )
}

export default PhoneHeader