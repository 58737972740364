import { Avatar, Center, Flex, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StatusBadge from '../../Utils/StatusBadge'
import HomepageEmptyTable from '../HomepageEmptyTable'
import EmptyCandidate from "../../Resources/Images/empty_candidate.svg";

import { fadeIn } from './Animation'

const ListCandidate = ({ teams }) => {
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(teams)) {
            setIsLoading(false);
        }
    }, [teams]);

    if (isLoading) {
        return (
            <Center flex={1} mt={"150px"}>
                <Spinner size="xl" color="brand.500" />
            </Center>
        );
    }

    const hasCandidates = Array.isArray(teams) && teams.some(team => Array.isArray(team.candidate) && team.candidate.length > 0);

    return (
        <Flex
            flex={1}
            gridArea={"2 / 1 / 4 / 3"}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            animation={`${fadeIn} .4s ease-in`}
        >
            {hasCandidates ? (
                <TableContainer flex={1} mt={4} overflowX={{ base: "scroll", md: "auto" }}>
                    <Table variant='unstyled' size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th color={"gray.500"} pb={4} width={"10px"} />
                                <Th color={"gray.500"} pb={4}>Nom</Th>
                                <Th color={"gray.500"} pb={4}>Prénom</Th>
                                <Th color={"gray.500"} pb={4}>Email</Th>
                                <Th color={"gray.500"} pb={4}>Statut</Th>
                            </Tr>
                        </Thead>
                        <Tbody borderTop="1px solid #E2E8F0">
                            {teams.map(team => (
                                <React.Fragment key={'team-' + team._id}>
                                    {Array.isArray(team.candidate) && [...team.candidate].reverse().slice(0, (10 / teams.length)).map((candidate, index) => {
                                        return (
                                            <Tr key={'candidate-' + index} color={"gray.700"} fontWeight={"semibold"} cursor={"pointer"} _hover={{ bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)" }} onClick={() => { navigate('/candidat/' + candidate._id) }}>
                                                <Td width={"10px"}>
                                                    <Avatar size='sm' name={candidate.firstname + ' ' + candidate.lastname} src={candidate.blob} />
                                                </Td>
                                                <Td>{candidate.lastname}</Td>
                                                <Td>{candidate.firstname}</Td>
                                                <Td>{candidate.email}</Td>
                                                <Td> <StatusBadge status={candidate.status} /> </Td>
                                            </Tr>
                                        )
                                    })}
                                </React.Fragment>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : <Flex justifyContent={"center"} direction={"column"} padding={20}>
                <HomepageEmptyTable text={"Vous n'avez aucun candidat pour le moment !"} illustration={EmptyCandidate} />
            </Flex>}
        </Flex>
    );
};

export default ListCandidate;