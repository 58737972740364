import { Badge, Flex } from '@chakra-ui/react'
import React from 'react'

const LicenceTag = () => {
    return (
        <Flex mt={-1} ml={1} alignItems="center">
            <Badge
                variant="subtle"
                size={'xs'}
                style={{
                    background: 'linear-gradient(to right, #B794F4, #6B46C1)',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Entreprise
            </Badge>
        </Flex>
    )
}

export default LicenceTag