import React, { useEffect } from 'react'
import { Tag, Box, Text, Flex, Code } from '@chakra-ui/react';
import { GetLabelFromType } from "../Utils/QuizzesUtils";

const QuizzesQuestion = ({ index, description, type, responses, answers }) => {

    return (
        <Box w='100%' borderRadius="md" boxShadow={"sm"} p={6} color="gray.700" className='secondary-gradient'>
            <Flex alignItems="baseline" gap={4} mb={4}>
                <Text as="i" fontSize={"sm"} color={"gray.500"}>Question numéro {index + 1}</Text>
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType(type)}</Tag>
            </Flex>
            <Text as='b' fontSize='2xl'>{description}</Text>
            {
                responses.length === 0 && type === "short" && <Text fontSize='sm'>Aucune résponse n'est proposée pour cette question.</Text>
            }
            {responses.map((res, index) =>
                <Text key={index} fontSize='md'>{String.fromCharCode(65 + index)} - <Code colorScheme={answers && answers.includes(index) ? "green" : "red"}>{res}</Code></Text>
            )}
        </Box>
    )
}

export default QuizzesQuestion