import { Box, Button, Container, Flex, FormControl, FormLabel, Image, Input, useToast } from '@chakra-ui/react'
import Footer from '../Layout/Footer'
import { useParams, useNavigate } from 'react-router-dom'
import AptitudeLogo from '../Resources/Logos/aptitude_recrutement.svg';
import { resetPassword, sendResetPasswordLink } from '../API/Users';

const PasswordReset = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { passwordInvitation } = useParams();

  const handleResetRequest = async (e) => {
    const formData = new FormData(e.target)
    const email = formData.get('email')

    await sendResetPasswordLink(email)

    toast({
      title: "Récupération de mot de passe",
      description: "Si un compte existe avec cette adresse mail, une demande de récupération de mot de passe vous a bien été envoyée.",
    })

  }

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const password = formData.get('password')
    const confirmPassword = formData.get('confirmPassword')

    const res = await resetPassword(passwordInvitation, password, confirmPassword)

    if (res.status === 200) {
      toast({
        title: 'Mot de passe mis à jour',
        status: 'success',
      })
      navigate('/')
      return;
    }

    const data = await res.json();

    toast({
      title: data.message ?? data.errors[0].msg,
      status: 'error',
    })

    if (data.redirect) {
      e.target.reset();
      navigate(data.redirect)
    }
  }

  return (
    <>
      <Container maxW="container.xl" p={4}>
        <Flex justifyContent="center" alignItems="center" minHeight={"60vh"} width={"100%"}>
          <Box width={"100%"} maxWidth={"800px"}>
            <Image mx={"auto"} mb={50} w="350px" src={AptitudeLogo} alt="logo aptitude" />
            {passwordInvitation ?
              <form onSubmit={handlePasswordChange}>
                <FormControl isRequired>
                  <FormLabel>Nouveau mot de passe</FormLabel>
                  <Input type="password" name="password" placeholder='Nouveau mot de passe' />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Confirmer le mot de passe</FormLabel>
                  <Input type="password" name="confirmPassword" placeholder='Confirmer le mot de passe' />
                </FormControl>
                <Button mt={4} colorScheme="brand" type="submit">Changer mon mot de passe</Button>
              </form>
              :
              <form onSubmit={(e) => {
                e.preventDefault()
                handleResetRequest(e)
              }}>
                <FormControl isRequired>
                  <FormLabel>Adresse e-mail</FormLabel>
                  <Input type="email" name="email" placeholder='Votre adresse e-mail' />
                </FormControl>
                <Flex gap={2} mt={4} ml="auto" width={"fit-content"} flexFlow={"row wrap"}>
                  <Button colorScheme="brand" variant={"ghost"} onClick={() => navigate("/")}>Revenir à la page de connexion</Button>
                  <Button colorScheme="brand" type="submit">Réinitialiser mon mot de passe</Button>
                </Flex>
              </form>
            }
          </Box>
        </Flex>
      </Container >
      <Footer />
    </>
  )
}
export default PasswordReset 