import { API_URL } from "../keys";
let csrfToken = null;

const getCsrfToken = async () => {
    if (!csrfToken) {
        const response = await fetch(API_URL + '/csrf-token', {
            credentials: 'include' // Nécessaire pour inclure les cookies
        });
        const data = await response.json();
        csrfToken = data.csrfToken; // Stocker le token
    }
    return csrfToken;
};

const csrfFetch = async (endpoint, options = {}) => {
    try {
        const token = await getCsrfToken();
        const url = `${API_URL}${endpoint}`;
        options.headers = {
            ...options.headers,
            'CSRF-Token': token
        };

        options.credentials = 'include';

        const response = await fetch(url, options);
        if (response.status === 403) {
            csrfToken = null; // Reset le token
            const newToken = await getCsrfToken();
            options.headers['CSRF-Token'] = newToken;

            return await fetch(url, options);
        }
        return response;
    } catch (error) {
        console.error('Erreur lors de la requête:', error);
    }
};

export default csrfFetch;