import { Flex, Skeleton, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { fadeIn } from './Animation'

const DashboardStatistic = ({ number, text, color, isMobile, isLoading }) => {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
        if (!isLoading) {
            const increment = Math.ceil(number / 50); // Détermine l'incrément pour atteindre la valeur finale
            const interval = setInterval(() => {
                setCurrentNumber((prev) => {
                    if (prev + increment >= number) {
                        clearInterval(interval);
                        return number; // Fixe la valeur finale
                    }
                    return prev + increment;
                });
            }, 25); // Ajuste la vitesse de l'animation
            return () => clearInterval(interval); // Nettoie l'intervalle
        }
    }, [number, isLoading]);

    if (isLoading)
        return (
            <Flex
                direction={"column"}
                boxShadow={"sm"}
                p={isMobile ? 2 : 4}
                background={"white"}
                flex={1}
                borderRadius={"xl"}
                animation={`${fadeIn} .4s ease-in`}
                className={color === "brand.500" ? "brand-gradient" : "secondary-gradient"}
                justifyContent={"space-between"}
            >
                <Text fontSize={isMobile ? "xs" : "sm"} fontWeight={"semibold"} color={color === "brand.500" ? "white" : "gray.700"}>{text}</Text>
                <Text fontSize={isMobile ? 24 : 40} fontWeight={"bold"} color={color === "brand.500" ? "white" : "gray.700"}>0</Text>
            </Flex>
        );

    return (
        <Flex
            direction={"column"}
            boxShadow={"md"} /* Augmentation de l'ombre pour un effet plus marqué */
            p={isMobile ? 2 : 4}
            flex={1}
            borderRadius={"xl"}
            animation={`${fadeIn} .4s ease-in`}
            className={color === "brand.500" ? "brand-gradient" : "secondary-gradient"}
            border={color === "brand.500" ? "none" : "1px solid rgba(255, 255, 255, 0.3)"} /* Bordure subtile pour glassmorphism */
            alignItems={"space-between"}
        >
            <Text
                fontSize={isMobile ? "xs" : "sm"}
                fontWeight={"semibold"}
                color={color === "brand.500" ? "white" : "gray.700"}
            >
                {text}
            </Text>
            <Text
                fontSize={isMobile ? 24 : 40}
                fontWeight={"bold"}
                color={color === "brand.500" ? "white" : "gray.700"}
            >
                {currentNumber}
            </Text>
        </Flex>
    );
};

export default DashboardStatistic;
