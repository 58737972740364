import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Textarea, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from '../Authentication/ProtectedRoute'
import { createNote } from "../API/Note";
import { handleFormErrors } from "../Utils/HandleFormErrors";

const AddNoteDialogueComponent = ({ isOpen, onClose, candidat, noteToUpdate, reload }) => {
    const { user } = useContext(UserContext);
    const [note, setNote] = useState();
    const [noteTitle, setNoteTitle] = useState();
    const [teamId, setTeamId] = useState();
    const [errors, setErrors] = useState({});
    const toast = useToast();

    useEffect(() => {
        setNote(noteToUpdate?.description || "");
        setNoteTitle(noteToUpdate?.title || "");
        setTeamId(noteToUpdate?.teamId || user && user.teams.length > 0 && user.teams[0]._id || "");
    }, [noteToUpdate])

    useMemo(() => {
        if (user && user.teams.length > 0) {
            setTeamId(user && user.teams[0]._id)
        }
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const method = noteToUpdate && noteToUpdate._id ? 'PUT' : 'POST';
        try {
            const response = await createNote(noteToUpdate, candidat, noteTitle, note, teamId, method);
            const data = await response.json();
            if (!response.ok) {
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }

                throw new Error('Failed to fetch data');
            }
            setNote("");
            setNoteTitle("");
            setErrors({});
            onClose();
            reload()
            toast({
                title: method === 'POST' ? 'Note ajoutée' : 'Note mise à jour',
            })
        } catch (error) {
            toast({
                title: 'Erreur lors de l\'ajout de la note',
                status: 'error',
            })
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => { onClose(); setErrors({}) }}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{!noteToUpdate ? "Ajouter" : "Modifier"} une note pour {candidat.firstname} {candidat.lastname}</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={4}>
                    <form onSubmit={handleSubmit}>
                        <FormControl isInvalid={errors && errors.teamId} mb={4}>
                            <FormLabel>Team</FormLabel>
                            <Select value={teamId || ""} onChange={e => setTeamId(e.target.value)}>
                                {user?.teams.map(team => (
                                    <option key={team._id} value={team._id}>{team.name}</option>
                                ))}
                            </Select>
                            {errors && errors.teamId && <FormErrorMessage>{errors.teamId}</FormErrorMessage>}
                        </FormControl>
                        <FormControl isInvalid={errors && errors.title} mb={4}>
                            <FormLabel>Titre</FormLabel>
                            <Input value={noteTitle} onChange={e => setNoteTitle(e.target.value)} />
                            {errors && errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                        </FormControl>
                        <FormControl isInvalid={errors && errors.description} mb={4}>
                            <FormLabel>Note</FormLabel>
                            <Textarea value={note} onChange={e => setNote(e.target.value)} />
                            {errors && errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                        </FormControl>
                        <Button width={"full"} type="submit" colorScheme="brand" mt={4}>{!noteToUpdate ? "Ajouter" : "Modifier"}</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}

export { AddNoteDialogueComponent }


