import { Box, Flex, IconButton, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaFileContract, FaPenToSquare, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { removeJobOffer } from '../../API/JobOffers';
import EmptyQuizzes from "../../Resources/Images/joboffers_empty.svg";
import { formatDateForPrint } from '../../Utils/FormatDate';
import PaginationTable from '../PaginationTable';
import { useSortableData } from '../useSortableData';
import QuizAssignation from './DialogJobOffers/QuizAssignation';
import TableDeletionDialog from './DialogJobOffers/TableDeletionDialog';

const PAGINATION_SIZE = 10;

const JobOffersTable = ({ jobOffers, reload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [toDeletion, setToDeletion] = useState(null)
    const toast = useToast();
    const [pageIndex, setPageIndex] = useState(0);
    const [paginationData, setPaginationData] = useState([]);
    const [jobToAssign, setJobToAssign] = useState(null);
    const { items: sortedJobOffers, requestSort, getSortDirection } = useSortableData(jobOffers);
    const { isOpen: isOpenQuiz, onOpen: onOpenQuiz, onClose: onCloseQuiz } = useDisclosure();

    const navigate = useNavigate();

    useEffect(() => {
        const paged = sortedJobOffers.slice(
            pageIndex * PAGINATION_SIZE,
            (pageIndex + 1) * PAGINATION_SIZE
        )

        if (pageIndex > 0 && paged.length === 0) {
            setPageIndex(pageIndex - 1)
        }

        setPaginationData(paged)
    }, [pageIndex, sortedJobOffers])

    const handleDeletion = async () => {
        try {
            if (!await removeJobOffer(toDeletion)) {
                throw new Error('Failed to fetch data');
            }
            toast({
                title: 'Offre d\'emploi supprimée'
            })
        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression de l\'offre d\'emploi',
                status: 'error',
            })
        } finally {
            reload()
        }
    }

    return jobOffers.length === 0 ?
        <Flex justifyContent={"center"}>
            <Box p={12}>
                <Image src={EmptyQuizzes} alt="No questionnaires" maxHeight={300} mb={10} />
                <Text textAlign={"center"} fontSize={"md"} color={"gray.600"}>Vos offres d'emploi apparaîtrons ici !</Text>
            </Box>
        </Flex>
        :
        <>
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr userSelect={"none"}>
                            <Th onClick={() => requestSort('name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nom</Text>{getSortDirection('name')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('team')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Équipe</Text>{getSortDirection('team')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('publicationDate')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Date de publication</Text>{getSortDirection('publicationDate')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('expirationDate')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Date de fin de l'offre</Text>{getSortDirection('expirationDate')}
                                </Flex>
                            </Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginationData.map((jobOffer, index) => (
                            <Tr key={index} cursor={"pointer"} _hover={{ bgGradient: "linear(to right,rgba(72, 187, 120, 0.2),rgba(47, 133, 90, 0.2))",backdropFilter: "blur(50px)" }}>
                                <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{jobOffer.name}</Td>
                                <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{jobOffer.team.name}</Td>
                                <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{formatDateForPrint(jobOffer.publicationDate)}</Td>
                                <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{jobOffer.expirationDate ? formatDateForPrint(jobOffer.expirationDate) : "Non renseigné"}</Td>
                                <Td>
                                    <Tooltip label="Ajouter un questionnaire" placement='top'>
                                        <IconButton
                                            rounded={"full"}
                                            variant="outline"
                                            mr={1}
                                            onClick={() => {
                                                setJobToAssign(jobOffer)
                                                onOpenQuiz()
                                            }}
                                            aria-label='Ajouter un questionnaire'
                                            icon={<FaFileContract />}
                                        />
                                    </Tooltip>
                                    <Tooltip label='Mettre à jour' placement='top'>
                                        <IconButton
                                            rounded={"full"}
                                            variant="outline"
                                            as={Link}
                                            to={`/offre-emploi/update/${jobOffer._id}`}
                                            aria-label='Update'
                                            colorScheme='brand'
                                            icon={<FaPenToSquare />}
                                            mr={1}
                                        />
                                    </Tooltip>
                                    <Tooltip label='Supprimer' placement='top'>
                                        <IconButton
                                            rounded={"full"}
                                            variant="outline"
                                            onClick={() => {
                                                setToDeletion(jobOffer._id)
                                                onOpen()
                                            }}
                                            colorScheme='red'
                                            aria-label='Delete'
                                            icon={<FaTrash />}
                                        />
                                    </Tooltip>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <PaginationTable pageSize={PAGINATION_SIZE} pageIndex={pageIndex} setPageIndex={setPageIndex} totalItemsCount={sortedJobOffers.length} showQuantity={true} />
            <TableDeletionDialog isOpen={isOpen} onClose={onClose} toDeletion={toDeletion} setToDeletion={setToDeletion} handleDeletion={handleDeletion} />
            <QuizAssignation isOpen={isOpenQuiz} onClose={onCloseQuiz} jobOffer={jobToAssign} setJobToAssign={setJobToAssign} />
        </>
}

export default JobOffersTable