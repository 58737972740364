import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import React from 'react'

const ExamHeaderComponent = ({ name, description, tags, _id, questions }) => {
    return (
        <Box w='100%' boxShadow={"md"} borderRadius="md" p={4} pt={2} color="white" className='brand-gradient' mb={8}>
            <Flex alignItems="center" gap={4}>
                <Text as='b' fontSize='4xl'>{name}</Text>
                <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag>
                <Flex ml="auto" gap={1}>
                    <Text as="b" >Nombre de questions : {questions && questions.length}</Text>
                </Flex>
            </Flex>
            <Flex alignItems="baseline" gap={4}>
                <Text fontSize='md'>{description}</Text>
            </Flex>
            <Flex alignItems="baseline" gap={1} mt={4}>
                {tags && tags.map((tag, index) => (
                    <Tag fontSize='xs' key={index} variant='solid' colorScheme="brand">{tag}</Tag>
                ))}

            </Flex>
        </Box>
    )
}

export default ExamHeaderComponent