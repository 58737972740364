import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Divider, Input, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

const AddCandidatToJobOfferDialog = ({ isOpen, onClose, jobOffer, cancelRef, allowedCandidateList, addCandidate }) => {
    const [filteredallowedCandidateList, setFilteredallowedCandidateList] = useState(null);

    useEffect(() => {
        setFilteredallowedCandidateList(allowedCandidateList)
    }, [allowedCandidateList])

    return (
        <AlertDialog
            size={"xl"}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Ajouter un candidat à l'offre
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Box mb='4'>
                            <Input
                                variant='flushed'
                                placeholder="Chercher un candidat..."
                                onChange={(e) => {
                                    let filteredCandidates = allowedCandidateList.filter(candidate => candidate.firstname.toLowerCase().includes(e.target.value.toLowerCase()) || candidate.lastname.toLowerCase().includes(e.target.value.toLowerCase()));
                                    setFilteredallowedCandidateList(filteredCandidates);
                                }}
                            />
                        </Box>


                        {filteredallowedCandidateList && filteredallowedCandidateList.length === 0 && <Text fontSize='sm'>Aucun candidat disponible</Text>}
                        {filteredallowedCandidateList && filteredallowedCandidateList.map((candidate, index) => {
                            return <React.Fragment key={index}>
                                <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                    <Text fontSize='sm'>{candidate.firstname} {candidate.lastname}</Text>
                                    <Button size={"sm"} variant={"ghost"} color={"brand.500"} onClick={() => {
                                        addCandidate(candidate._id)
                                    }}>Lier le candidat à cette offre</Button>
                                </Box>
                                <Divider />
                            </React.Fragment>
                        })}
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                onClose()
                            }}>
                                Fermer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default AddCandidatToJobOfferDialog