import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NavbarLinkItem = ({ logo, text, link, isActive, largerThanLg, isExpanded, isMobile }) => {

    const [fullTextBar, setFullTextBar] = useState(true)

    useEffect(() => {
        if (largerThanLg && isExpanded) {
            setFullTextBar(true);
        } else if (isMobile) {
            setFullTextBar(true);
        } else {
            setFullTextBar(false);
        }
    }, [largerThanLg, isExpanded, isMobile]);

    return (
        <Tooltip label={!fullTextBar ? text : ""} placement='right'>
            <Flex
                justifyContent={"start"}
                w={fullTextBar ? "100%" : "fit-content"}
                h={isMobile ? 39 : "18%"}
                alignItems={"center"}
                gap={4}
                p={3}
                bg={isActive && fullTextBar ? "brand.500" : null}
                borderRadius={fullTextBar ? 8 : 50}
                transition={"0.2s"}
                _hover={fullTextBar ? { bg: "brand.500", color: "white" } : { ".navbar-link-icon": { bg: "brand.600", color: "white" } }}
                color={isActive ? "white" : "gray.600"}
                cursor={"pointer"}
                as={Link}
                to={link}
            >
                <Box
                    className='navbar-link-icon'
                    bg={isActive ? "brand.500" : isMobile ? null : "gray.600"}
                    p={3}
                    borderRadius={"xl"}
                >
                    {logo}
                </Box>
                <Text
                    fontWeight="semibold"
                    fontSize="md"
                    display={fullTextBar ? "flex" : "none"}
                >{text}</Text>
            </Flex>
        </Tooltip>
    )
}

export default NavbarLinkItem